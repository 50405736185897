@charset "UTF-8";

/* Christmas */
@font-face {
	font-family: "cristmas";
	src:url("christmas/webfont/fonts/cristmas.eot");
	src:url("christmas/webfont/fonts/cristmas.eot?#iefix") format("embedded-opentype"),
	url("christmas/webfont/fonts/cristmas.woff") format("woff"),
	url("christmas/webfont/fonts/cristmas.ttf") format("truetype"),
	url("christmas/webfont/fonts/cristmas.svg#cristmas") format("svg");
	font-weight: normal;
	font-style: normal;
}

[data-icon-christmas]:before {
	font-family: "cristmas" !important;
	content: attr(data-icon-christmas);
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[class^="icon-christmas"]:before,
[class*=" icon-christmas"]:before {
	font-family: "cristmas" !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-christmas-001:before {
	content: "a";
}
.icon-christmas-014:before {
	content: "b";
}
.icon-christmas-015:before {
	content: "c";
}
.icon-christmas-002:before {
	content: "d";
}
.icon-christmas-003:before {
	content: "e";
}
.icon-christmas-016:before {
	content: "f";
}
.icon-christmas-017:before {
	content: "g";
}
.icon-christmas-004:before {
	content: "h";
}
.icon-christmas-005:before {
	content: "i";
}
.icon-christmas-018:before {
	content: "j";
}
.icon-christmas-019:before {
	content: "k";
}
.icon-christmas-006:before {
	content: "l";
}
.icon-christmas-007:before {
	content: "m";
}
.icon-christmas-020:before {
	content: "n";
}
.icon-christmas-021:before {
	content: "o";
}
.icon-christmas-008:before {
	content: "p";
}
.icon-christmas-009:before {
	content: "q";
}
.icon-christmas-022:before {
	content: "r";
}
.icon-christmas-023:before {
	content: "s";
}
.icon-christmas-010:before {
	content: "t";
}
.icon-christmas-011:before {
	content: "u";
}
.icon-christmas-024:before {
	content: "v";
}
.icon-christmas-025:before {
	content: "w";
}
.icon-christmas-012:before {
	content: "x";
}
.icon-christmas-013:before {
	content: "y";
}
.icon-christmas-026:before {
	content: "z";
}
.icon-christmas-039:before {
	content: "A";
}
.icon-christmas-038:before {
	content: "B";
}
.icon-christmas-051:before {
	content: "C";
}
.icon-christmas-052:before {
	content: "D";
}
.icon-christmas-065:before {
	content: "E";
}
.icon-christmas-064:before {
	content: "F";
}
.icon-christmas-063:before {
	content: "G";
}
.icon-christmas-050:before {
	content: "H";
}
.icon-christmas-037:before {
	content: "I";
}
.icon-christmas-036:before {
	content: "J";
}
.icon-christmas-049:before {
	content: "K";
}
.icon-christmas-062:before {
	content: "L";
}
.icon-christmas-061:before {
	content: "M";
}
.icon-christmas-048:before {
	content: "N";
}
.icon-christmas-035:before {
	content: "O";
}
.icon-christmas-034:before {
	content: "P";
}
.icon-christmas-047:before {
	content: "Q";
}
.icon-christmas-060:before {
	content: "R";
}
.icon-christmas-059:before {
	content: "S";
}
.icon-christmas-046:before {
	content: "T";
}
.icon-christmas-033:before {
	content: "U";
}
.icon-christmas-032:before {
	content: "V";
}
.icon-christmas-045:before {
	content: "W";
}
.icon-christmas-058:before {
	content: "X";
}
.icon-christmas-031:before {
	content: "Y";
}
.icon-christmas-044:before {
	content: "Z";
}
.icon-christmas-057:before {
	content: "0";
}
.icon-christmas-056:before {
	content: "1";
}
.icon-christmas-043:before {
	content: "2";
}
.icon-christmas-030:before {
	content: "3";
}
.icon-christmas-029:before {
	content: "4";
}
.icon-christmas-042:before {
	content: "5";
}
.icon-christmas-055:before {
	content: "6";
}
.icon-christmas-054:before {
	content: "7";
}
.icon-christmas-041:before {
	content: "8";
}
.icon-christmas-028:before {
	content: "9";
}
.icon-christmas-027:before {
	content: "!";
}
.icon-christmas-040:before {
	content: "\"";
}
.icon-christmas-053:before {
	content: "#";
}
.icon-christmas-066:before {
	content: "$";
}
.icon-christmas-079:before {
	content: "%";
}
.icon-christmas-092:before {
	content: "&";
}
.icon-christmas-093:before {
	content: "'";
}
.icon-christmas-080:before {
	content: "(";
}
.icon-christmas-067:before {
	content: ")";
}
.icon-christmas-068:before {
	content: "*";
}
.icon-christmas-081:before {
	content: "+";
}
.icon-christmas-094:before {
	content: ",";
}
.icon-christmas-095:before {
	content: "-";
}
.icon-christmas-082:before {
	content: ".";
}
.icon-christmas-069:before {
	content: "/";
}
.icon-christmas-070:before {
	content: ":";
}
.icon-christmas-083:before {
	content: ";";
}
.icon-christmas-096:before {
	content: "<";
}
.icon-christmas-097:before {
	content: "=";
}
.icon-christmas-084:before {
	content: ">";
}
.icon-christmas-071:before {
	content: "?";
}
.icon-christmas-072:before {
	content: "@";
}
.icon-christmas-085:before {
	content: "[";
}
.icon-christmas-098:before {
	content: "]";
}
.icon-christmas-099:before {
	content: "^";
}
.icon-christmas-086:before {
	content: "_";
}
.icon-christmas-073:before {
	content: "`";
}
.icon-christmas-074:before {
	content: "{";
}
.icon-christmas-087:before {
	content: "|";
}
.icon-christmas-100:before {
	content: "}";
}
.icon-christmas-088:before {
	content: "~";
}
.icon-christmas-075:before {
	content: "\\";
}
.icon-christmas-076:before {
	content: "\e000";
}
.icon-christmas-089:before {
	content: "\e001";
}
.icon-christmas-090:before {
	content: "\e002";
}
.icon-christmas-077:before {
	content: "\e003";
}
.icon-christmas-078:before {
	content: "\e004";
}
.icon-christmas-091:before {
	content: "\e005";
}


/* Clothes */
@font-face {
	font-family: "clothes";
	src:url("clothes/webfont/fonts/clothes.eot");
	src:url("clothes/webfont/fonts/clothes.eot?#iefix") format("embedded-opentype"),
	url("clothes/webfont/fonts/clothes.woff") format("woff"),
 	url("clothes/webfont/fonts/clothes.ttf") format("truetype"),
	url("clothes/webfont/fonts/clothes.svg#clothes") format("svg");
	font-weight: normal;
	font-style: normal;
}

[data-icon-clothes]:before {
	font-family: "clothes" !important;
	content: attr(data-icon-clothes);
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

[class^="icon-clothes"]:before,
[class*=" icon-clothes"]:before {
	font-family: "clothes" !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-clothes-014:before {
	content: "a";
}
.icon-clothes-027:before {
	content: "b";
}
.icon-clothes-040:before {
	content: "c";
}
.icon-clothes-053:before {
	content: "d";
}
.icon-clothes-066:before {
	content: "e";
}
.icon-clothes-079:before {
	content: "f";
}
.icon-clothes-092:before {
	content: "g";
}
.icon-clothes-093:before {
	content: "h";
}
.icon-clothes-080:before {
	content: "i";
}
.icon-clothes-067:before {
	content: "j";
}
.icon-clothes-054:before {
	content: "k";
}
.icon-clothes-041:before {
	content: "l";
}
.icon-clothes-028:before {
	content: "m";
}
.icon-clothes-015:before {
	content: "n";
}
.icon-clothes-016:before {
	content: "o";
}
.icon-clothes-029:before {
	content: "p";
}
.icon-clothes-042:before {
	content: "q";
}
.icon-clothes-055:before {
	content: "r";
}
.icon-clothes-068:before {
	content: "s";
}
.icon-clothes-081:before {
	content: "t";
}
.icon-clothes-094:before {
	content: "u";
}
.icon-clothes-095:before {
	content: "v";
}
.icon-clothes-082:before {
	content: "w";
}
.icon-clothes-083:before {
	content: "x";
}
.icon-clothes-096:before {
	content: "y";
}
.icon-clothes-097:before {
	content: "z";
}
.icon-clothes-084:before {
	content: "A";
}
.icon-clothes-085:before {
	content: "B";
}
.icon-clothes-098:before {
	content: "C";
}
.icon-clothes-099:before {
	content: "D";
}
.icon-clothes-086:before {
	content: "E";
}
.icon-clothes-087:before {
	content: "F";
}
.icon-clothes-100:before {
	content: "G";
}
.icon-clothes-088:before {
	content: "H";
}
.icon-clothes-089:before {
	content: "I";
}
.icon-clothes-090:before {
	content: "J";
}
.icon-clothes-091:before {
	content: "K";
}
.icon-clothes-078:before {
	content: "L";
}
.icon-clothes-077:before {
	content: "M";
}
.icon-clothes-076:before {
	content: "N";
}
.icon-clothes-075:before {
	content: "O";
}
.icon-clothes-074:before {
	content: "P";
}
.icon-clothes-073:before {
	content: "Q";
}
.icon-clothes-072:before {
	content: "R";
}
.icon-clothes-071:before {
	content: "S";
}
.icon-clothes-070:before {
	content: "T";
}
.icon-clothes-069:before {
	content: "U";
}
.icon-clothes-056:before {
	content: "V";
}
.icon-clothes-043:before {
	content: "W";
}
.icon-clothes-030:before {
	content: "X";
}
.icon-clothes-017:before {
	content: "Y";
}
.icon-clothes-018:before {
	content: "Z";
}
.icon-clothes-031:before {
	content: "0";
}
.icon-clothes-044:before {
	content: "1";
}
.icon-clothes-057:before {
	content: "2";
}
.icon-clothes-058:before {
	content: "3";
}
.icon-clothes-045:before {
	content: "4";
}
.icon-clothes-032:before {
	content: "5";
}
.icon-clothes-019:before {
	content: "6";
}
.icon-clothes-020:before {
	content: "7";
}
.icon-clothes-033:before {
	content: "8";
}
.icon-clothes-046:before {
	content: "9";
}
.icon-clothes-059:before {
	content: "!";
}
.icon-clothes-060:before {
	content: "\"";
}
.icon-clothes-047:before {
	content: "#";
}
.icon-clothes-034:before {
	content: "$";
}
.icon-clothes-021:before {
	content: "%";
}
.icon-clothes-022:before {
	content: "&";
}
.icon-clothes-035:before {
	content: "'";
}
.icon-clothes-048:before {
	content: "(";
}
.icon-clothes-061:before {
	content: ")";
}
.icon-clothes-062:before {
	content: "*";
}
.icon-clothes-049:before {
	content: "+";
}
.icon-clothes-036:before {
	content: ",";
}
.icon-clothes-023:before {
	content: "-";
}
.icon-clothes-024:before {
	content: ".";
}
.icon-clothes-037:before {
	content: "/";
}
.icon-clothes-050:before {
	content: ":";
}
.icon-clothes-063:before {
	content: ";";
}
.icon-clothes-064:before {
	content: "<";
}
.icon-clothes-051:before {
	content: "=";
}
.icon-clothes-038:before {
	content: ">";
}
.icon-clothes-025:before {
	content: "?";
}
.icon-clothes-026:before {
	content: "@";
}
.icon-clothes-039:before {
	content: "[";
}
.icon-clothes-052:before {
	content: "]";
}
.icon-clothes-065:before {
	content: "^";
}
.icon-clothes-001:before {
	content: "_";
}
.icon-clothes-002:before {
	content: "`";
}
.icon-clothes-003:before {
	content: "{";
}
.icon-clothes-004:before {
	content: "|";
}
.icon-clothes-005:before {
	content: "}";
}
.icon-clothes-006:before {
	content: "~";
}
.icon-clothes-007:before {
	content: "\\";
}
.icon-clothes-008:before {
	content: "\e000";
}
.icon-clothes-009:before {
	content: "\e001";
}
.icon-clothes-010:before {
	content: "\e002";
}
.icon-clothes-011:before {
	content: "\e003";
}
.icon-clothes-012:before {
	content: "\e004";
}
.icon-clothes-013:before {
	content: "\e005";
}

/* Comunnication */
@font-face {
	font-family: "communication-48-x-48";
	src:url("communication/webfont/fonts/communication-48-x-48.eot");
	src:url("communication/webfont/fonts/communication-48-x-48.eot?#iefix") format("embedded-opentype"),
	url("communication/webfont/fonts/communication-48-x-48.woff") format("woff"),
	url("communication/webfont/fonts/communication-48-x-48.ttf") format("truetype"),
	url("communication/webfont/fonts/communication-48-x-48.svg#communication-48-x-48") format("svg");
	font-weight: normal;
	font-style: normal;
}

[data-icon-communication]:before {
	font-family: "communication-48-x-48" !important;
	content: attr(data-icon-communication);
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	}

[class^="icon-communication"]:before,
[class*=" icon-communication"]:before {
	font-family: "communication-48-x-48" !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-communication-001:before {
	content: "a";
}
.icon-communication-002:before {
	content: "b";
}
.icon-communication-003:before {
	content: "c";
}
.icon-communication-004:before {
	content: "d";
}
.icon-communication-005:before {
	content: "e";
}
.icon-communication-006:before {
	content: "f";
}
.icon-communication-008:before {
	content: "g";
}
.icon-communication-007:before {
	content: "h";
}
.icon-communication-009:before {
	content: "i";
}
.icon-communication-010:before {
	content: "j";
}
.icon-communication-011:before {
	content: "k";
}
.icon-communication-012:before {
	content: "l";
}
.icon-communication-013:before {
	content: "m";
}
.icon-communication-026:before {
	content: "n";
}
.icon-communication-025:before {
	content: "o";
}
.icon-communication-024:before {
	content: "p";
}
.icon-communication-023:before {
	content: "q";
}
.icon-communication-022:before {
	content: "r";
}
.icon-communication-020:before {
	content: "s";
}
.icon-communication-019:before {
	content: "t";
}
.icon-communication-018:before {
	content: "u";
}
.icon-communication-016:before {
	content: "v";
}
.icon-communication-015:before {
	content: "w";
}
.icon-communication-014:before {
	content: "x";
}
.icon-communication-021:before {
	content: "y";
}
.icon-communication-017:before {
	content: "z";
}
.icon-communication-027:before {
	content: "A";
}
.icon-communication-040:before {
	content: "B";
}
.icon-communication-053:before {
	content: "C";
}
.icon-communication-054:before {
	content: "D";
}
.icon-communication-041:before {
	content: "E";
}
.icon-communication-028:before {
	content: "F";
}
.icon-communication-029:before {
	content: "G";
}
.icon-communication-042:before {
	content: "H";
}
.icon-communication-055:before {
	content: "I";
}
.icon-communication-056:before {
	content: "J";
}
.icon-communication-043:before {
	content: "K";
}
.icon-communication-030:before {
	content: "L";
}
.icon-communication-031:before {
	content: "M";
}
.icon-communication-044:before {
	content: "N";
}
.icon-communication-057:before {
	content: "O";
}
.icon-communication-058:before {
	content: "P";
}
.icon-communication-045:before {
	content: "Q";
}
.icon-communication-032:before {
	content: "R";
}
.icon-communication-033:before {
	content: "S";
}
.icon-communication-046:before {
	content: "T";
}
.icon-communication-059:before {
	content: "U";
}
.icon-communication-060:before {
	content: "V";
}
.icon-communication-047:before {
	content: "W";
}
.icon-communication-034:before {
	content: "X";
}
.icon-communication-035:before {
	content: "Y";
}
.icon-communication-048:before {
	content: "Z";
}
.icon-communication-061:before {
	content: "0";
}
.icon-communication-062:before {
	content: "1";
}
.icon-communication-049:before {
	content: "2";
}
.icon-communication-036:before {
	content: "3";
}
.icon-communication-037:before {
	content: "4";
}
.icon-communication-050:before {
	content: "5";
}
.icon-communication-063:before {
	content: "6";
}
.icon-communication-064:before {
	content: "7";
}
.icon-communication-051:before {
	content: "8";
}
.icon-communication-038:before {
	content: "9";
}
.icon-communication-039:before {
	content: "!";
}
.icon-communication-052:before {
	content: "\"";
}
.icon-communication-065:before {
	content: "#";
}
.icon-communication-066:before {
	content: "$";
}
.icon-communication-079:before {
	content: "%";
}
.icon-communication-092:before {
	content: "&";
}
.icon-communication-105:before {
	content: "'";
}
.icon-communication-106:before {
	content: "(";
}
.icon-communication-093:before {
	content: ")";
}
.icon-communication-080:before {
	content: "*";
}
.icon-communication-067:before {
	content: "+";
}
.icon-communication-068:before {
	content: ",";
}
.icon-communication-081:before {
	content: "-";
}
.icon-communication-082:before {
	content: ".";
}
.icon-communication-069:before {
	content: "/";
}
.icon-communication-070:before {
	content: ":";
}
.icon-communication-083:before {
	content: ";";
}
.icon-communication-084:before {
	content: "<";
}
.icon-communication-071:before {
	content: "=";
}
.icon-communication-072:before {
	content: ">";
}
.icon-communication-085:before {
	content: "?";
}
.icon-communication-086:before {
	content: "@";
}
.icon-communication-073:before {
	content: "[";
}
.icon-communication-074:before {
	content: "]";
}
.icon-communication-087:before {
	content: "^";
}
.icon-communication-088:before {
	content: "_";
}
.icon-communication-075:before {
	content: "`";
}
.icon-communication-076:before {
	content: "{";
}
.icon-communication-089:before {
	content: "|";
}
.icon-communication-090:before {
	content: "}";
}
.icon-communication-077:before {
	content: "~";
}
.icon-communication-078:before {
	content: "\\";
}
.icon-communication-091:before {
	content: "\e000";
}
.icon-communication-104:before {
	content: "\e001";
}
.icon-communication-117:before {
	content: "\e002";
}
.icon-communication-116:before {
	content: "\e003";
}
.icon-communication-103:before {
	content: "\e004";
}
.icon-communication-102:before {
	content: "\e005";
}
.icon-communication-115:before {
	content: "\e006";
}
.icon-communication-114:before {
	content: "\e007";
}
.icon-communication-101:before {
	content: "\e008";
}
.icon-communication-100:before {
	content: "\e009";
}
.icon-communication-113:before {
	content: "\e00a";
}
.icon-communication-112:before {
	content: "\e00b";
}
.icon-communication-099:before {
	content: "\e00c";
}
.icon-communication-098:before {
	content: "\e00d";
}
.icon-communication-111:before {
	content: "\e00e";
}
.icon-communication-110:before {
	content: "\e00f";
}
.icon-communication-097:before {
	content: "\e010";
}
.icon-communication-096:before {
	content: "\e011";
}
.icon-communication-109:before {
	content: "\e012";
}
.icon-communication-108:before {
	content: "\e013";
}
.icon-communication-095:before {
	content: "\e014";
}
.icon-communication-094:before {
	content: "\e015";
}
.icon-communication-107:before {
	content: "\e016";
}
.icon-communication-118:before {
	content: "\e017";
}
.icon-communication-131:before {
	content: "\e018";
}
.icon-communication-144:before {
	content: "\e019";
}
.icon-communication-157:before {
	content: "\e01a";
}
.icon-communication-170:before {
	content: "\e01b";
}
.icon-communication-171:before {
	content: "\e01c";
}
.icon-communication-158:before {
	content: "\e01d";
}
.icon-communication-145:before {
	content: "\e01e";
}
.icon-communication-132:before {
	content: "\e01f";
}
.icon-communication-119:before {
	content: "\e020";
}
.icon-communication-120:before {
	content: "\e021";
}
.icon-communication-133:before {
	content: "\e022";
}
.icon-communication-146:before {
	content: "\e023";
}
.icon-communication-159:before {
	content: "\e024";
}
.icon-communication-172:before {
	content: "\e025";
}
.icon-communication-173:before {
	content: "\e026";
}
.icon-communication-160:before {
	content: "\e027";
}
.icon-communication-147:before {
	content: "\e028";
}
.icon-communication-134:before {
	content: "\e029";
}
.icon-communication-121:before {
	content: "\e02a";
}
.icon-communication-122:before {
	content: "\e02b";
}
.icon-communication-135:before {
	content: "\e02c";
}
.icon-communication-148:before {
	content: "\e02d";
}
.icon-communication-161:before {
	content: "\e02e";
}
.icon-communication-174:before {
	content: "\e02f";
}
.icon-communication-175:before {
	content: "\e030";
}
.icon-communication-162:before {
	content: "\e031";
}
.icon-communication-149:before {
	content: "\e032";
}
.icon-communication-136:before {
	content: "\e033";
}
.icon-communication-123:before {
	content: "\e034";
}
.icon-communication-124:before {
	content: "\e035";
}
.icon-communication-137:before {
	content: "\e036";
}
.icon-communication-150:before {
	content: "\e037";
}
.icon-communication-163:before {
	content: "\e038";
}
.icon-communication-176:before {
	content: "\e039";
}
.icon-communication-177:before {
	content: "\e03a";
}
.icon-communication-164:before {
	content: "\e03b";
}
.icon-communication-151:before {
	content: "\e03c";
}
.icon-communication-138:before {
	content: "\e03d";
}
.icon-communication-125:before {
	content: "\e03e";
}
.icon-communication-126:before {
	content: "\e03f";
}
.icon-communication-139:before {
	content: "\e040";
}
.icon-communication-152:before {
	content: "\e041";
}
.icon-communication-165:before {
	content: "\e042";
}
.icon-communication-178:before {
	content: "\e043";
}
.icon-communication-179:before {
	content: "\e044";
}
.icon-communication-166:before {
	content: "\e045";
}
.icon-communication-153:before {
	content: "\e046";
}
.icon-communication-140:before {
	content: "\e047";
}
.icon-communication-127:before {
	content: "\e048";
}
.icon-communication-128:before {
	content: "\e049";
}
.icon-communication-141:before {
	content: "\e04a";
}
.icon-communication-154:before {
	content: "\e04b";
}
.icon-communication-167:before {
	content: "\e04c";
}
.icon-communication-180:before {
	content: "\e04d";
}
.icon-communication-168:before {
	content: "\e04e";
}
.icon-communication-169:before {
	content: "\e04f";
}
.icon-communication-156:before {
	content: "\e050";
}
.icon-communication-155:before {
	content: "\e051";
}
.icon-communication-142:before {
	content: "\e052";
}
.icon-communication-143:before {
	content: "\e053";
}
.icon-communication-130:before {
	content: "\e054";
}
.icon-communication-129:before {
	content: "\e055";
}

/* Education */
@font-face {
	font-family: "education-48";
	src:url("education/webfont/fonts/education-48.eot");
	src:url("education/webfont/fonts/education-48.eot?#iefix") format("embedded-opentype"),
	url("education/webfont/fonts/education-48.woff") format("woff"),
	url("education/webfont/fonts/education-48.ttf") format("truetype"),
	url("education/webfont/fonts/education-48.svg#education-48") format("svg");
	font-weight: normal;
	font-style: normal;
}

[data-icon-education]:before {
	font-family: "education-48" !important;
	content: attr(data-icon-education);
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

[class^="icon-education"]:before,
[class*=" icon-education"]:before {
	font-family: "education-48" !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-education-001:before {
	content: "a";
}
.icon-education-014:before {
	content: "b";
}
.icon-education-027:before {
	content: "c";
}
.icon-education-028:before {
	content: "d";
}
.icon-education-015:before {
	content: "e";
}
.icon-education-002:before {
	content: "f";
}
.icon-education-003:before {
	content: "g";
}
.icon-education-016:before {
	content: "h";
}
.icon-education-029:before {
	content: "i";
}
.icon-education-030:before {
	content: "j";
}
.icon-education-017:before {
	content: "k";
}
.icon-education-004:before {
	content: "l";
}
.icon-education-005:before {
	content: "m";
}
.icon-education-018:before {
	content: "n";
}
.icon-education-031:before {
	content: "o";
}
.icon-education-032:before {
	content: "p";
}
.icon-education-019:before {
	content: "q";
}
.icon-education-006:before {
	content: "r";
}
.icon-education-007:before {
	content: "s";
}
.icon-education-020:before {
	content: "t";
}
.icon-education-033:before {
	content: "u";
}
.icon-education-034:before {
	content: "v";
}
.icon-education-021:before {
	content: "w";
}
.icon-education-008:before {
	content: "x";
}
.icon-education-009:before {
	content: "y";
}
.icon-education-022:before {
	content: "z";
}
.icon-education-035:before {
	content: "A";
}
.icon-education-036:before {
	content: "B";
}
.icon-education-023:before {
	content: "C";
}
.icon-education-010:before {
	content: "D";
}
.icon-education-011:before {
	content: "E";
}
.icon-education-024:before {
	content: "F";
}
.icon-education-037:before {
	content: "G";
}
.icon-education-038:before {
	content: "H";
}
.icon-education-025:before {
	content: "I";
}
.icon-education-012:before {
	content: "J";
}
.icon-education-013:before {
	content: "K";
}
.icon-education-026:before {
	content: "L";
}
.icon-education-039:before {
	content: "M";
}
.icon-education-052:before {
	content: "N";
}
.icon-education-065:before {
	content: "O";
}
.icon-education-078:before {
	content: "P";
}
.icon-education-091:before {
	content: "Q";
}
.icon-education-104:before {
	content: "R";
}
.icon-education-117:before {
	content: "S";
}
.icon-education-130:before {
	content: "T";
}
.icon-education-143:before {
	content: "U";
}
.icon-education-142:before {
	content: "V";
}
.icon-education-129:before {
	content: "W";
}
.icon-education-116:before {
	content: "X";
}
.icon-education-103:before {
	content: "Y";
}
.icon-education-090:before {
	content: "Z";
}
.icon-education-077:before {
	content: "0";
}
.icon-education-064:before {
	content: "1";
}
.icon-education-051:before {
	content: "2";
}
.icon-education-050:before {
	content: "3";
}
.icon-education-063:before {
	content: "4";
}
.icon-education-076:before {
	content: "5";
}
.icon-education-089:before {
	content: "6";
}
.icon-education-088:before {
	content: "7";
}
.icon-education-075:before {
	content: "8";
}
.icon-education-062:before {
	content: "9";
}
.icon-education-049:before {
	content: "!";
}
.icon-education-048:before {
	content: "\"";
}
.icon-education-061:before {
	content: "#";
}
.icon-education-074:before {
	content: "$";
}
.icon-education-087:before {
	content: "%";
}
.icon-education-100:before {
	content: "&";
}
.icon-education-101:before {
	content: "'";
}
.icon-education-102:before {
	content: "(";
}
.icon-education-115:before {
	content: ")";
}
.icon-education-114:before {
	content: "*";
}
.icon-education-113:before {
	content: "+";
}
.icon-education-126:before {
	content: ",";
}
.icon-education-127:before {
	content: "-";
}
.icon-education-128:before {
	content: ".";
}
.icon-education-141:before {
	content: "/";
}
.icon-education-140:before {
	content: ":";
}
.icon-education-139:before {
	content: ";";
}
.icon-education-138:before {
	content: "<";
}
.icon-education-125:before {
	content: "=";
}
.icon-education-124:before {
	content: ">";
}
.icon-education-137:before {
	content: "?";
}
.icon-education-136:before {
	content: "@";
}
.icon-education-123:before {
	content: "[";
}
.icon-education-110:before {
	content: "]";
}
.icon-education-111:before {
	content: "^";
}
.icon-education-112:before {
	content: "_";
}
.icon-education-099:before {
	content: "`";
}
.icon-education-098:before {
	content: "{";
}
.icon-education-097:before {
	content: "|";
}
.icon-education-084:before {
	content: "}";
}
.icon-education-085:before {
	content: "~";
}
.icon-education-086:before {
	content: "\\";
}
.icon-education-073:before {
	content: "\e000";
}
.icon-education-072:before {
	content: "\e001";
}
.icon-education-071:before {
	content: "\e002";
}
.icon-education-058:before {
	content: "\e003";
}
.icon-education-059:before {
	content: "\e004";
}
.icon-education-060:before {
	content: "\e005";
}
.icon-education-047:before {
	content: "\e006";
}
.icon-education-046:before {
	content: "\e007";
}
.icon-education-045:before {
	content: "\e008";
}
.icon-education-040:before {
	content: "\e009";
}
.icon-education-041:before {
	content: "\e00a";
}
.icon-education-054:before {
	content: "\e00b";
}
.icon-education-053:before {
	content: "\e00c";
}
.icon-education-066:before {
	content: "\e00d";
}
.icon-education-067:before {
	content: "\e00e";
}
.icon-education-068:before {
	content: "\e00f";
}
.icon-education-055:before {
	content: "\e010";
}
.icon-education-042:before {
	content: "\e011";
}
.icon-education-043:before {
	content: "\e012";
}
.icon-education-056:before {
	content: "\e013";
}
.icon-education-069:before {
	content: "\e014";
}
.icon-education-070:before {
	content: "\e015";
}
.icon-education-057:before {
	content: "\e016";
}
.icon-education-044:before {
	content: "\e017";
}
.icon-education-083:before {
	content: "\e018";
}
.icon-education-082:before {
	content: "\e019";
}
.icon-education-081:before {
	content: "\e01a";
}
.icon-education-080:before {
	content: "\e01b";
}
.icon-education-079:before {
	content: "\e01c";
}
.icon-education-092:before {
	content: "\e01d";
}
.icon-education-105:before {
	content: "\e01e";
}
.icon-education-118:before {
	content: "\e01f";
}
.icon-education-131:before {
	content: "\e020";
}
.icon-education-132:before {
	content: "\e021";
}
.icon-education-119:before {
	content: "\e022";
}
.icon-education-106:before {
	content: "\e023";
}
.icon-education-093:before {
	content: "\e024";
}
.icon-education-094:before {
	content: "\e025";
}
.icon-education-107:before {
	content: "\e026";
}
.icon-education-120:before {
	content: "\e027";
}
.icon-education-133:before {
	content: "\e028";
}
.icon-education-134:before {
	content: "\e029";
}
.icon-education-108:before {
	content: "\e02a";
}
.icon-education-095:before {
	content: "\e02b";
}
.icon-education-096:before {
	content: "\e02c";
}
.icon-education-109:before {
	content: "\e02d";
}
.icon-education-122:before {
	content: "\e02e";
}
.icon-education-121:before {
	content: "\e02f";
}
.icon-education-135:before {
	content: "\e030";
}
.icon-education-144:before {
	content: "\e031";
}
.icon-education-157:before {
	content: "\e032";
}
.icon-education-170:before {
	content: "\e033";
}
.icon-education-183:before {
	content: "\e034";
}
.icon-education-196:before {
	content: "\e035";
}
.icon-education-197:before {
	content: "\e036";
}
.icon-education-184:before {
	content: "\e037";
}
.icon-education-171:before {
	content: "\e038";
}
.icon-education-158:before {
	content: "\e039";
}
.icon-education-145:before {
	content: "\e03a";
}
.icon-education-146:before {
	content: "\e03b";
}
.icon-education-159:before {
	content: "\e03c";
}
.icon-education-172:before {
	content: "\e03d";
}
.icon-education-185:before {
	content: "\e03e";
}
.icon-education-198:before {
	content: "\e03f";
}
.icon-education-199:before {
	content: "\e040";
}
.icon-education-186:before {
	content: "\e041";
}
.icon-education-173:before {
	content: "\e042";
}
.icon-education-160:before {
	content: "\e043";
}
.icon-education-147:before {
	content: "\e044";
}
.icon-education-148:before {
	content: "\e045";
}
.icon-education-161:before {
	content: "\e046";
}
.icon-education-174:before {
	content: "\e047";
}
.icon-education-187:before {
	content: "\e048";
}
.icon-education-200:before {
	content: "\e049";
}
.icon-education-188:before {
	content: "\e04a";
}
.icon-education-175:before {
	content: "\e04b";
}
.icon-education-162:before {
	content: "\e04c";
}
.icon-education-149:before {
	content: "\e04d";
}
.icon-education-150:before {
	content: "\e04e";
}
.icon-education-163:before {
	content: "\e04f";
}
.icon-education-176:before {
	content: "\e050";
}
.icon-education-189:before {
	content: "\e051";
}
.icon-education-190:before {
	content: "\e052";
}
.icon-education-177:before {
	content: "\e053";
}
.icon-education-164:before {
	content: "\e054";
}
.icon-education-151:before {
	content: "\e055";
}
.icon-education-152:before {
	content: "\e056";
}
.icon-education-165:before {
	content: "\e057";
}
.icon-education-178:before {
	content: "\e058";
}
.icon-education-191:before {
	content: "\e059";
}
.icon-education-192:before {
	content: "\e05a";
}
.icon-education-179:before {
	content: "\e05b";
}
.icon-education-166:before {
	content: "\e05c";
}
.icon-education-153:before {
	content: "\e05d";
}
.icon-education-154:before {
	content: "\e05e";
}
.icon-education-167:before {
	content: "\e05f";
}
.icon-education-180:before {
	content: "\e060";
}
.icon-education-193:before {
	content: "\e061";
}
.icon-education-194:before {
	content: "\e062";
}
.icon-education-181:before {
	content: "\e063";
}
.icon-education-168:before {
	content: "\e064";
}
.icon-education-155:before {
	content: "\e065";
}
.icon-education-156:before {
	content: "\e066";
}
.icon-education-169:before {
	content: "\e067";
}
.icon-education-182:before {
	content: "\e068";
}
.icon-education-195:before {
	content: "\e069";
}

/* Electronics */
@font-face {
	font-family: "electronics";
	src:url("electronics/webfont/fonts/electronics.eot");
	src:url("electronics/webfont/fonts/electronics.eot?#iefix") format("embedded-opentype"),
	url("electronics/webfont/fonts/electronics.woff") format("woff"),
	url("electronics/webfont/fonts/electronics.ttf") format("truetype"),
	url("electronics/webfont/fonts/electronics.svg#electronics") format("svg");
	font-weight: normal;
	font-style: normal;
}

[data-icon-electronics]:before {
	font-family: "electronics" !important;
	content: attr(data-icon-electronics);
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

[class^="icon-electronics"]:before,
[class*=" icon-electronics"]:before {
	font-family: "electronics" !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-electronics-001:before {
	content: "a";
}
.icon-electronics-014:before {
	content: "b";
}
.icon-electronics-027:before {
	content: "c";
}
.icon-electronics-040:before {
	content: "d";
}
.icon-electronics-053:before {
	content: "e";
}
.icon-electronics-066:before {
	content: "f";
}
.icon-electronics-079:before {
	content: "g";
}
.icon-electronics-092:before {
	content: "h";
}
.icon-electronics-093:before {
	content: "i";
}
.icon-electronics-080:before {
	content: "j";
}
.icon-electronics-067:before {
	content: "k";
}
.icon-electronics-054:before {
	content: "l";
}
.icon-electronics-041:before {
	content: "m";
}
.icon-electronics-028:before {
	content: "n";
}
.icon-electronics-015:before {
	content: "o";
}
.icon-electronics-002:before {
	content: "p";
}
.icon-electronics-003:before {
	content: "q";
}
.icon-electronics-016:before {
	content: "r";
}
.icon-electronics-029:before {
	content: "s";
}
.icon-electronics-042:before {
	content: "t";
}
.icon-electronics-055:before {
	content: "u";
}
.icon-electronics-068:before {
	content: "v";
}
.icon-electronics-081:before {
	content: "w";
}
.icon-electronics-094:before {
	content: "x";
}
.icon-electronics-095:before {
	content: "y";
}
.icon-electronics-082:before {
	content: "z";
}
.icon-electronics-069:before {
	content: "A";
}
.icon-electronics-056:before {
	content: "B";
}
.icon-electronics-043:before {
	content: "C";
}
.icon-electronics-030:before {
	content: "D";
}
.icon-electronics-017:before {
	content: "E";
}
.icon-electronics-004:before {
	content: "F";
}
.icon-electronics-005:before {
	content: "G";
}
.icon-electronics-018:before {
	content: "H";
}
.icon-electronics-031:before {
	content: "I";
}
.icon-electronics-044:before {
	content: "J";
}
.icon-electronics-057:before {
	content: "K";
}
.icon-electronics-070:before {
	content: "L";
}
.icon-electronics-083:before {
	content: "M";
}
.icon-electronics-096:before {
	content: "N";
}
.icon-electronics-097:before {
	content: "O";
}
.icon-electronics-084:before {
	content: "P";
}
.icon-electronics-071:before {
	content: "Q";
}
.icon-electronics-058:before {
	content: "R";
}
.icon-electronics-045:before {
	content: "S";
}
.icon-electronics-032:before {
	content: "T";
}
.icon-electronics-019:before {
	content: "U";
}
.icon-electronics-006:before {
	content: "V";
}
.icon-electronics-007:before {
	content: "W";
}
.icon-electronics-020:before {
	content: "X";
}
.icon-electronics-033:before {
	content: "Y";
}
.icon-electronics-046:before {
	content: "Z";
}
.icon-electronics-059:before {
	content: "0";
}
.icon-electronics-072:before {
	content: "1";
}
.icon-electronics-085:before {
	content: "2";
}
.icon-electronics-098:before {
	content: "3";
}
.icon-electronics-099:before {
	content: "4";
}
.icon-electronics-086:before {
	content: "5";
}
.icon-electronics-073:before {
	content: "6";
}
.icon-electronics-060:before {
	content: "7";
}
.icon-electronics-047:before {
	content: "8";
}
.icon-electronics-034:before {
	content: "9";
}
.icon-electronics-021:before {
	content: "!";
}
.icon-electronics-008:before {
	content: "\"";
}
.icon-electronics-009:before {
	content: "#";
}
.icon-electronics-022:before {
	content: "$";
}
.icon-electronics-035:before {
	content: "%";
}
.icon-electronics-048:before {
	content: "&";
}
.icon-electronics-049:before {
	content: "'";
}
.icon-electronics-036:before {
	content: "(";
}
.icon-electronics-023:before {
	content: ")";
}
.icon-electronics-010:before {
	content: "*";
}
.icon-electronics-011:before {
	content: "+";
}
.icon-electronics-024:before {
	content: ",";
}
.icon-electronics-025:before {
	content: "-";
}
.icon-electronics-012:before {
	content: ".";
}
.icon-electronics-013:before {
	content: "/";
}
.icon-electronics-026:before {
	content: ":";
}
.icon-electronics-039:before {
	content: ";";
}
.icon-electronics-052:before {
	content: "<";
}
.icon-electronics-065:before {
	content: "=";
}
.icon-electronics-078:before {
	content: ">";
}
.icon-electronics-091:before {
	content: "?";
}
.icon-electronics-104:before {
	content: "@";
}
.icon-electronics-103:before {
	content: "[";
}
.icon-electronics-090:before {
	content: "]";
}
.icon-electronics-064:before {
	content: "^";
}
.icon-electronics-051:before {
	content: "_";
}
.icon-electronics-038:before {
	content: "`";
}
.icon-electronics-037:before {
	content: "{";
}
.icon-electronics-050:before {
	content: "|";
}
.icon-electronics-063:before {
	content: "}";
}
.icon-electronics-076:before {
	content: "~";
}
.icon-electronics-077:before {
	content: "\\";
}
.icon-electronics-062:before {
	content: "\e000";
}
.icon-electronics-061:before {
	content: "\e001";
}
.icon-electronics-074:before {
	content: "\e002";
}
.icon-electronics-075:before {
	content: "\e003";
}
.icon-electronics-088:before {
	content: "\e004";
}
.icon-electronics-087:before {
	content: "\e005";
}
.icon-electronics-089:before {
	content: "\e006";
}
.icon-electronics-102:before {
	content: "\e007";
}
.icon-electronics-101:before {
	content: "\e008";
}
.icon-electronics-100:before {
	content: "\e009";
}
.icon-electronics-105:before {
	content: "\e00a";
}
.icon-electronics-118:before {
	content: "\e00b";
}
.icon-electronics-131:before {
	content: "\e00c";
}
.icon-electronics-144:before {
	content: "\e00d";
}
.icon-electronics-157:before {
	content: "\e00e";
}
.icon-electronics-158:before {
	content: "\e00f";
}
.icon-electronics-145:before {
	content: "\e010";
}
.icon-electronics-132:before {
	content: "\e011";
}
.icon-electronics-119:before {
	content: "\e012";
}
.icon-electronics-106:before {
	content: "\e013";
}
.icon-electronics-107:before {
	content: "\e014";
}
.icon-electronics-120:before {
	content: "\e015";
}
.icon-electronics-133:before {
	content: "\e016";
}
.icon-electronics-146:before {
	content: "\e017";
}
.icon-electronics-159:before {
	content: "\e018";
}
.icon-electronics-160:before {
	content: "\e019";
}
.icon-electronics-147:before {
	content: "\e01a";
}
.icon-electronics-134:before {
	content: "\e01b";
}
.icon-electronics-121:before {
	content: "\e01c";
}
.icon-electronics-108:before {
	content: "\e01d";
}
.icon-electronics-109:before {
	content: "\e01e";
}
.icon-electronics-122:before {
	content: "\e01f";
}
.icon-electronics-135:before {
	content: "\e020";
}
.icon-electronics-148:before {
	content: "\e021";
}
.icon-electronics-149:before {
	content: "\e022";
}
.icon-electronics-136:before {
	content: "\e023";
}
.icon-electronics-123:before {
	content: "\e024";
}
.icon-electronics-110:before {
	content: "\e025";
}
.icon-electronics-111:before {
	content: "\e026";
}
.icon-electronics-124:before {
	content: "\e027";
}
.icon-electronics-137:before {
	content: "\e028";
}
.icon-electronics-150:before {
	content: "\e029";
}
.icon-electronics-151:before {
	content: "\e02a";
}
.icon-electronics-138:before {
	content: "\e02b";
}
.icon-electronics-125:before {
	content: "\e02c";
}
.icon-electronics-112:before {
	content: "\e02d";
}
.icon-electronics-113:before {
	content: "\e02e";
}
.icon-electronics-126:before {
	content: "\e02f";
}
.icon-electronics-139:before {
	content: "\e030";
}
.icon-electronics-152:before {
	content: "\e031";
}
.icon-electronics-153:before {
	content: "\e032";
}
.icon-electronics-140:before {
	content: "\e033";
}
.icon-electronics-127:before {
	content: "\e034";
}
.icon-electronics-114:before {
	content: "\e035";
}
.icon-electronics-115:before {
	content: "\e036";
}
.icon-electronics-128:before {
	content: "\e037";
}
.icon-electronics-141:before {
	content: "\e038";
}
.icon-electronics-154:before {
	content: "\e039";
}
.icon-electronics-155:before {
	content: "\e03a";
}
.icon-electronics-142:before {
	content: "\e03b";
}
.icon-electronics-129:before {
	content: "\e03c";
}
.icon-electronics-116:before {
	content: "\e03d";
}
.icon-electronics-117:before {
	content: "\e03e";
}
.icon-electronics-130:before {
	content: "\e03f";
}
.icon-electronics-143:before {
	content: "\e040";
}
.icon-electronics-156:before {
	content: "\e041";
}


/* Finance */
@font-face {
	font-family: "finance";
	src:url("finance/webfont/fonts/finance.eot");
	src:url("finance/webfont/fonts/finance.eot?#iefix") format("embedded-opentype"),
	url("finance/webfont/fonts/finance.woff") format("woff"),
	url("finance/webfont/fonts/finance.ttf") format("truetype"),
	url("finance/webfont/fonts/finance.svg#finance") format("svg");
	font-weight: normal;
	font-style: normal;
}

[data-icon-finance]:before {
	font-family: "finance" !important;
	content: attr(data-icon-finance);
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

[class^="icon-finance"]:before,
[class*=" icon-finance"]:before {
	font-family: "finance" !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-finance-260:before {
	content: "a";
}
.icon-finance-259:before {
	content: "b";
}
.icon-finance-246:before {
	content: "c";
}
.icon-finance-247:before {
	content: "d";
}
.icon-finance-234:before {
	content: "e";
}
.icon-finance-233:before {
	content: "f";
}
.icon-finance-232:before {
	content: "g";
}
.icon-finance-245:before {
	content: "h";
}
.icon-finance-258:before {
	content: "i";
}
.icon-finance-257:before {
	content: "j";
}
.icon-finance-256:before {
	content: "k";
}
.icon-finance-243:before {
	content: "l";
}
.icon-finance-244:before {
	content: "m";
}
.icon-finance-231:before {
	content: "n";
}
.icon-finance-230:before {
	content: "o";
}
.icon-finance-229:before {
	content: "p";
}
.icon-finance-242:before {
	content: "q";
}
.icon-finance-255:before {
	content: "r";
}
.icon-finance-254:before {
	content: "s";
}
.icon-finance-241:before {
	content: "t";
}
.icon-finance-228:before {
	content: "u";
}
.icon-finance-227:before {
	content: "v";
}
.icon-finance-240:before {
	content: "w";
}
.icon-finance-253:before {
	content: "x";
}
.icon-finance-252:before {
	content: "y";
}
.icon-finance-239:before {
	content: "z";
}
.icon-finance-226:before {
	content: "A";
}
.icon-finance-225:before {
	content: "B";
}
.icon-finance-238:before {
	content: "C";
}
.icon-finance-251:before {
	content: "D";
}
.icon-finance-250:before {
	content: "E";
}
.icon-finance-237:before {
	content: "F";
}
.icon-finance-224:before {
	content: "G";
}
.icon-finance-223:before {
	content: "H";
}
.icon-finance-236:before {
	content: "I";
}
.icon-finance-249:before {
	content: "J";
}
.icon-finance-235:before {
	content: "K";
}
.icon-finance-222:before {
	content: "L";
}
.icon-finance-248:before {
	content: "M";
}
.icon-finance-209:before {
	content: "N";
}
.icon-finance-196:before {
	content: "O";
}
.icon-finance-183:before {
	content: "P";
}
.icon-finance-184:before {
	content: "Q";
}
.icon-finance-197:before {
	content: "R";
}
.icon-finance-210:before {
	content: "S";
}
.icon-finance-211:before {
	content: "T";
}
.icon-finance-198:before {
	content: "U";
}
.icon-finance-185:before {
	content: "V";
}
.icon-finance-186:before {
	content: "W";
}
.icon-finance-199:before {
	content: "X";
}
.icon-finance-212:before {
	content: "Y";
}
.icon-finance-213:before {
	content: "Z";
}
.icon-finance-200:before {
	content: "0";
}
.icon-finance-187:before {
	content: "1";
}
.icon-finance-188:before {
	content: "2";
}
.icon-finance-189:before {
	content: "3";
}
.icon-finance-201:before {
	content: "4";
}
.icon-finance-214:before {
	content: "5";
}
.icon-finance-215:before {
	content: "6";
}
.icon-finance-202:before {
	content: "7";
}
.icon-finance-216:before {
	content: "8";
}
.icon-finance-203:before {
	content: "9";
}
.icon-finance-204:before {
	content: "!";
}
.icon-finance-217:before {
	content: "\"";
}
.icon-finance-191:before {
	content: "#";
}
.icon-finance-190:before {
	content: "$";
}
.icon-finance-192:before {
	content: "%";
}
.icon-finance-205:before {
	content: "&";
}
.icon-finance-218:before {
	content: "'";
}
.icon-finance-219:before {
	content: "(";
}
.icon-finance-206:before {
	content: ")";
}
.icon-finance-193:before {
	content: "*";
}
.icon-finance-194:before {
	content: "+";
}
.icon-finance-207:before {
	content: ",";
}
.icon-finance-220:before {
	content: "-";
}
.icon-finance-221:before {
	content: ".";
}
.icon-finance-208:before {
	content: "/";
}
.icon-finance-195:before {
	content: ":";
}
.icon-finance-182:before {
	content: ";";
}
.icon-finance-181:before {
	content: "<";
}
.icon-finance-180:before {
	content: "=";
}
.icon-finance-167:before {
	content: ">";
}
.icon-finance-168:before {
	content: "?";
}
.icon-finance-169:before {
	content: "@";
}
.icon-finance-156:before {
	content: "[";
}
.icon-finance-155:before {
	content: "]";
}
.icon-finance-154:before {
	content: "^";
}
.icon-finance-141:before {
	content: "_";
}
.icon-finance-142:before {
	content: "`";
}
.icon-finance-143:before {
	content: "{";
}
.icon-finance-130:before {
	content: "|";
}
.icon-finance-129:before {
	content: "}";
}
.icon-finance-128:before {
	content: "~";
}
.icon-finance-127:before {
	content: "\\";
}
.icon-finance-140:before {
	content: "\e000";
}
.icon-finance-139:before {
	content: "\e001";
}
.icon-finance-126:before {
	content: "\e002";
}
.icon-finance-125:before {
	content: "\e003";
}
.icon-finance-138:before {
	content: "\e004";
}
.icon-finance-151:before {
	content: "\e005";
}
.icon-finance-152:before {
	content: "\e006";
}
.icon-finance-153:before {
	content: "\e007";
}
.icon-finance-166:before {
	content: "\e008";
}
.icon-finance-179:before {
	content: "\e009";
}
.icon-finance-178:before {
	content: "\e00a";
}
.icon-finance-165:before {
	content: "\e00b";
}
.icon-finance-164:before {
	content: "\e00c";
}
.icon-finance-177:before {
	content: "\e00d";
}
.icon-finance-176:before {
	content: "\e00e";
}
.icon-finance-163:before {
	content: "\e00f";
}
.icon-finance-150:before {
	content: "\e010";
}
.icon-finance-137:before {
	content: "\e011";
}
.icon-finance-124:before {
	content: "\e012";
}
.icon-finance-123:before {
	content: "\e013";
}
.icon-finance-136:before {
	content: "\e014";
}
.icon-finance-149:before {
	content: "\e015";
}
.icon-finance-162:before {
	content: "\e016";
}
.icon-finance-175:before {
	content: "\e017";
}
.icon-finance-174:before {
	content: "\e018";
}
.icon-finance-161:before {
	content: "\e019";
}
.icon-finance-148:before {
	content: "\e01a";
}
.icon-finance-135:before {
	content: "\e01b";
}
.icon-finance-122:before {
	content: "\e01c";
}
.icon-finance-121:before {
	content: "\e01d";
}
.icon-finance-134:before {
	content: "\e01e";
}
.icon-finance-147:before {
	content: "\e01f";
}
.icon-finance-160:before {
	content: "\e020";
}
.icon-finance-173:before {
	content: "\e021";
}
.icon-finance-172:before {
	content: "\e022";
}
.icon-finance-146:before {
	content: "\e023";
}
.icon-finance-133:before {
	content: "\e024";
}
.icon-finance-120:before {
	content: "\e025";
}
.icon-finance-159:before {
	content: "\e026";
}
.icon-finance-158:before {
	content: "\e027";
}
.icon-finance-171:before {
	content: "\e028";
}
.icon-finance-170:before {
	content: "\e029";
}
.icon-finance-157:before {
	content: "\e02a";
}
.icon-finance-144:before {
	content: "\e02b";
}
.icon-finance-145:before {
	content: "\e02c";
}
.icon-finance-132:before {
	content: "\e02d";
}
.icon-finance-131:before {
	content: "\e02e";
}
.icon-finance-118:before {
	content: "\e02f";
}
.icon-finance-119:before {
	content: "\e030";
}
.icon-finance-106:before {
	content: "\e031";
}
.icon-finance-105:before {
	content: "\e032";
}
.icon-finance-092:before {
	content: "\e033";
}
.icon-finance-093:before {
	content: "\e034";
}
.icon-finance-107:before {
	content: "\e035";
}
.icon-finance-094:before {
	content: "\e036";
}
.icon-finance-095:before {
	content: "\e037";
}
.icon-finance-108:before {
	content: "\e038";
}
.icon-finance-109:before {
	content: "\e039";
}
.icon-finance-096:before {
	content: "\e03a";
}
.icon-finance-097:before {
	content: "\e03b";
}
.icon-finance-110:before {
	content: "\e03c";
}
.icon-finance-111:before {
	content: "\e03d";
}
.icon-finance-098:before {
	content: "\e03e";
}
.icon-finance-099:before {
	content: "\e03f";
}
.icon-finance-112:before {
	content: "\e040";
}
.icon-finance-113:before {
	content: "\e041";
}
.icon-finance-100:before {
	content: "\e042";
}
.icon-finance-101:before {
	content: "\e043";
}
.icon-finance-114:before {
	content: "\e044";
}
.icon-finance-115:before {
	content: "\e045";
}
.icon-finance-102:before {
	content: "\e046";
}
.icon-finance-116:before {
	content: "\e048";
}
.icon-finance-117:before {
	content: "\e049";
}
.icon-finance-104:before {
	content: "\e047";
}
.icon-finance-103:before {
	content: "\e04a";
}
.icon-finance-014:before {
	content: "\e04b";
}
.icon-finance-027:before {
	content: "\e04c";
}
.icon-finance-040:before {
	content: "\e04d";
}
.icon-finance-053:before {
	content: "\e04e";
}
.icon-finance-066:before {
	content: "\e04f";
}
.icon-finance-079:before {
	content: "\e050";
}
.icon-finance-080:before {
	content: "\e051";
}
.icon-finance-067:before {
	content: "\e052";
}
.icon-finance-068:before {
	content: "\e053";
}
.icon-finance-081:before {
	content: "\e054";
}
.icon-finance-070:before {
	content: "\e055";
}
.icon-finance-083:before {
	content: "\e056";
}
.icon-finance-082:before {
	content: "\e057";
}
.icon-finance-069:before {
	content: "\e058";
}
.icon-finance-071:before {
	content: "\e059";
}
.icon-finance-084:before {
	content: "\e05a";
}
.icon-finance-085:before {
	content: "\e05b";
}
.icon-finance-072:before {
	content: "\e05c";
}
.icon-finance-073:before {
	content: "\e05d";
}
.icon-finance-086:before {
	content: "\e05e";
}
.icon-finance-087:before {
	content: "\e05f";
}
.icon-finance-074:before {
	content: "\e060";
}
.icon-finance-075:before {
	content: "\e061";
}
.icon-finance-088:before {
	content: "\e062";
}
.icon-finance-089:before {
	content: "\e063";
}
.icon-finance-076:before {
	content: "\e064";
}
.icon-finance-077:before {
	content: "\e065";
}
.icon-finance-090:before {
	content: "\e066";
}
.icon-finance-091:before {
	content: "\e067";
}
.icon-finance-078:before {
	content: "\e068";
}
.icon-finance-065:before {
	content: "\e069";
}
.icon-finance-052:before {
	content: "\e06a";
}
.icon-finance-039:before {
	content: "\e06b";
}
.icon-finance-026:before {
	content: "\e06c";
}
.icon-finance-025:before {
	content: "\e06d";
}
.icon-finance-038:before {
	content: "\e06e";
}
.icon-finance-051:before {
	content: "\e06f";
}
.icon-finance-064:before {
	content: "\e070";
}
.icon-finance-063:before {
	content: "\e071";
}
.icon-finance-050:before {
	content: "\e072";
}
.icon-finance-037:before {
	content: "\e073";
}
.icon-finance-024:before {
	content: "\e074";
}
.icon-finance-023:before {
	content: "\e075";
}
.icon-finance-036:before {
	content: "\e076";
}
.icon-finance-049:before {
	content: "\e077";
}
.icon-finance-062:before {
	content: "\e078";
}
.icon-finance-061:before {
	content: "\e079";
}
.icon-finance-048:before {
	content: "\e07a";
}
.icon-finance-035:before {
	content: "\e07b";
}
.icon-finance-022:before {
	content: "\e07c";
}
.icon-finance-021:before {
	content: "\e07d";
}
.icon-finance-034:before {
	content: "\e07e";
}
.icon-finance-047:before {
	content: "\e07f";
}
.icon-finance-060:before {
	content: "\e080";
}
.icon-finance-059:before {
	content: "\e081";
}
.icon-finance-046:before {
	content: "\e082";
}
.icon-finance-033:before {
	content: "\e083";
}
.icon-finance-020:before {
	content: "\e084";
}
.icon-finance-019:before {
	content: "\e085";
}
.icon-finance-032:before {
	content: "\e086";
}
.icon-finance-045:before {
	content: "\e087";
}
.icon-finance-058:before {
	content: "\e088";
}
.icon-finance-057:before {
	content: "\e089";
}
.icon-finance-044:before {
	content: "\e08a";
}
.icon-finance-031:before {
	content: "\e08b";
}
.icon-finance-018:before {
	content: "\e08c";
}
.icon-finance-017:before {
	content: "\e08d";
}
.icon-finance-030:before {
	content: "\e08e";
}
.icon-finance-043:before {
	content: "\e08f";
}
.icon-finance-056:before {
	content: "\e090";
}
.icon-finance-055:before {
	content: "\e091";
}
.icon-finance-042:before {
	content: "\e092";
}
.icon-finance-029:before {
	content: "\e093";
}
.icon-finance-016:before {
	content: "\e094";
}
.icon-finance-015:before {
	content: "\e095";
}
.icon-finance-028:before {
	content: "\e096";
}
.icon-finance-041:before {
	content: "\e097";
}
.icon-finance-054:before {
	content: "\e098";
}
.icon-finance-001:before {
	content: "\e099";
}
.icon-finance-002:before {
	content: "\e09a";
}
.icon-finance-003:before {
	content: "\e09b";
}
.icon-finance-004:before {
	content: "\e09c";
}
.icon-finance-005:before {
	content: "\e09d";
}
.icon-finance-006:before {
	content: "\e09e";
}
.icon-finance-007:before {
	content: "\e09f";
}
.icon-finance-008:before {
	content: "\e0a0";
}
.icon-finance-009:before {
	content: "\e0a1";
}
.icon-finance-010:before {
	content: "\e0a2";
}
.icon-finance-011:before {
	content: "\e0a3";
}
.icon-finance-012:before {
	content: "\e0a4";
}
.icon-finance-013:before {
	content: "\e0a5";
}

/* Food */
@font-face {
	font-family: "food-48";
	src:url("food/webfont/fonts/food-48.eot");
	src:url("food/webfont/fonts/food-48.eot?#iefix") format("embedded-opentype"),
	url("food/webfont/fonts/food-48.woff") format("woff"),
	url("food/webfont/fonts/food-48.ttf") format("truetype"),
	url("food/webfont/fonts/food-48.svg#food-48") format("svg");
	font-weight: normal;
	font-style: normal;
}

[data-icon-food]:before {
	font-family: "food-48" !important;
	content: attr(data-icon-food);
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

[class^="icon-food"]:before,
[class*=" icon-food"]:before {
	font-family: "food-48" !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-food-001:before {
	content: "a";
}
.icon-food-014:before {
	content: "b";
}
.icon-food-027:before {
	content: "c";
}
.icon-food-040:before {
	content: "d";
}
.icon-food-041:before {
	content: "e";
}
.icon-food-028:before {
	content: "f";
}
.icon-food-015:before {
	content: "g";
}
.icon-food-002:before {
	content: "h";
}
.icon-food-003:before {
	content: "i";
}
.icon-food-016:before {
	content: "j";
}
.icon-food-029:before {
	content: "k";
}
.icon-food-042:before {
	content: "l";
}
.icon-food-043:before {
	content: "m";
}
.icon-food-030:before {
	content: "n";
}
.icon-food-017:before {
	content: "o";
}
.icon-food-004:before {
	content: "p";
}
.icon-food-005:before {
	content: "q";
}
.icon-food-018:before {
	content: "r";
}
.icon-food-031:before {
	content: "s";
}
.icon-food-044:before {
	content: "t";
}
.icon-food-045:before {
	content: "u";
}
.icon-food-032:before {
	content: "v";
}
.icon-food-019:before {
	content: "w";
}
.icon-food-006:before {
	content: "x";
}
.icon-food-007:before {
	content: "y";
}
.icon-food-020:before {
	content: "z";
}
.icon-food-033:before {
	content: "A";
}
.icon-food-046:before {
	content: "B";
}
.icon-food-047:before {
	content: "C";
}
.icon-food-034:before {
	content: "D";
}
.icon-food-021:before {
	content: "E";
}
.icon-food-008:before {
	content: "F";
}
.icon-food-009:before {
	content: "G";
}
.icon-food-022:before {
	content: "H";
}
.icon-food-035:before {
	content: "I";
}
.icon-food-048:before {
	content: "J";
}
.icon-food-049:before {
	content: "K";
}
.icon-food-036:before {
	content: "L";
}
.icon-food-023:before {
	content: "M";
}
.icon-food-010:before {
	content: "N";
}
.icon-food-011:before {
	content: "O";
}
.icon-food-024:before {
	content: "P";
}
.icon-food-037:before {
	content: "Q";
}
.icon-food-050:before {
	content: "R";
}
.icon-food-051:before {
	content: "S";
}
.icon-food-038:before {
	content: "T";
}
.icon-food-025:before {
	content: "U";
}
.icon-food-012:before {
	content: "V";
}
.icon-food-013:before {
	content: "W";
}
.icon-food-026:before {
	content: "X";
}
.icon-food-039:before {
	content: "Y";
}
.icon-food-052:before {
	content: "Z";
}
.icon-food-065:before {
	content: "0";
}
.icon-food-078:before {
	content: "1";
}
.icon-food-091:before {
	content: "2";
}
.icon-food-104:before {
	content: "3";
}
.icon-food-103:before {
	content: "4";
}
.icon-food-090:before {
	content: "5";
}
.icon-food-077:before {
	content: "6";
}
.icon-food-064:before {
	content: "7";
}
.icon-food-063:before {
	content: "8";
}
.icon-food-076:before {
	content: "9";
}
.icon-food-089:before {
	content: "!";
}
.icon-food-102:before {
	content: "\"";
}
.icon-food-101:before {
	content: "#";
}
.icon-food-088:before {
	content: "$";
}
.icon-food-075:before {
	content: "%";
}
.icon-food-062:before {
	content: "&";
}
.icon-food-061:before {
	content: "'";
}
.icon-food-074:before {
	content: "(";
}
.icon-food-087:before {
	content: ")";
}
.icon-food-100:before {
	content: "*";
}
.icon-food-099:before {
	content: "+";
}
.icon-food-086:before {
	content: ",";
}
.icon-food-073:before {
	content: "-";
}
.icon-food-060:before {
	content: ".";
}
.icon-food-059:before {
	content: "/";
}
.icon-food-072:before {
	content: ":";
}
.icon-food-085:before {
	content: ";";
}
.icon-food-098:before {
	content: "<";
}
.icon-food-097:before {
	content: "=";
}
.icon-food-084:before {
	content: ">";
}
.icon-food-071:before {
	content: "?";
}
.icon-food-058:before {
	content: "@";
}
.icon-food-057:before {
	content: "[";
}
.icon-food-070:before {
	content: "]";
}
.icon-food-083:before {
	content: "^";
}
.icon-food-096:before {
	content: "_";
}
.icon-food-095:before {
	content: "`";
}
.icon-food-082:before {
	content: "{";
}
.icon-food-069:before {
	content: "|";
}
.icon-food-056:before {
	content: "}";
}
.icon-food-055:before {
	content: "~";
}
.icon-food-068:before {
	content: "\\";
}
.icon-food-081:before {
	content: "\e000";
}
.icon-food-094:before {
	content: "\e001";
}
.icon-food-093:before {
	content: "\e002";
}
.icon-food-080:before {
	content: "\e003";
}
.icon-food-067:before {
	content: "\e004";
}
.icon-food-054:before {
	content: "\e005";
}
.icon-food-053:before {
	content: "\e006";
}
.icon-food-066:before {
	content: "\e007";
}
.icon-food-079:before {
	content: "\e008";
}
.icon-food-092:before {
	content: "\e009";
}
.icon-food-105:before {
	content: "\e00a";
}
.icon-food-118:before {
	content: "\e00b";
}
.icon-food-131:before {
	content: "\e00c";
}
.icon-food-144:before {
	content: "\e00d";
}
.icon-food-157:before {
	content: "\e00e";
}
.icon-food-170:before {
	content: "\e00f";
}
.icon-food-171:before {
	content: "\e010";
}
.icon-food-158:before {
	content: "\e011";
}
.icon-food-145:before {
	content: "\e012";
}
.icon-food-132:before {
	content: "\e013";
}
.icon-food-119:before {
	content: "\e014";
}
.icon-food-106:before {
	content: "\e015";
}
.icon-food-107:before {
	content: "\e016";
}
.icon-food-120:before {
	content: "\e017";
}
.icon-food-133:before {
	content: "\e018";
}
.icon-food-146:before {
	content: "\e019";
}
.icon-food-159:before {
	content: "\e01a";
}
.icon-food-172:before {
	content: "\e01b";
}
.icon-food-173:before {
	content: "\e01c";
}
.icon-food-160:before {
	content: "\e01d";
}
.icon-food-147:before {
	content: "\e01e";
}
.icon-food-134:before {
	content: "\e01f";
}
.icon-food-121:before {
	content: "\e020";
}
.icon-food-108:before {
	content: "\e021";
}
.icon-food-109:before {
	content: "\e022";
}
.icon-food-122:before {
	content: "\e023";
}
.icon-food-135:before {
	content: "\e024";
}
.icon-food-148:before {
	content: "\e025";
}
.icon-food-161:before {
	content: "\e026";
}
.icon-food-174:before {
	content: "\e027";
}
.icon-food-175:before {
	content: "\e028";
}
.icon-food-162:before {
	content: "\e029";
}
.icon-food-149:before {
	content: "\e02a";
}
.icon-food-136:before {
	content: "\e02b";
}
.icon-food-123:before {
	content: "\e02c";
}
.icon-food-110:before {
	content: "\e02d";
}
.icon-food-111:before {
	content: "\e02e";
}
.icon-food-124:before {
	content: "\e02f";
}
.icon-food-137:before {
	content: "\e030";
}
.icon-food-150:before {
	content: "\e031";
}
.icon-food-163:before {
	content: "\e032";
}
.icon-food-176:before {
	content: "\e033";
}
.icon-food-177:before {
	content: "\e034";
}
.icon-food-164:before {
	content: "\e035";
}
.icon-food-151:before {
	content: "\e036";
}
.icon-food-138:before {
	content: "\e037";
}
.icon-food-125:before {
	content: "\e038";
}
.icon-food-112:before {
	content: "\e039";
}
.icon-food-113:before {
	content: "\e03a";
}
.icon-food-126:before {
	content: "\e03b";
}
.icon-food-139:before {
	content: "\e03c";
}
.icon-food-152:before {
	content: "\e03d";
}
.icon-food-165:before {
	content: "\e03e";
}
.icon-food-178:before {
	content: "\e03f";
}
.icon-food-191:before {
	content: "\e040";
}
.icon-food-192:before {
	content: "\e041";
}
.icon-food-166:before {
	content: "\e042";
}
.icon-food-153:before {
	content: "\e043";
}
.icon-food-140:before {
	content: "\e044";
}
.icon-food-127:before {
	content: "\e045";
}
.icon-food-114:before {
	content: "\e046";
}
.icon-food-115:before {
	content: "\e047";
}
.icon-food-128:before {
	content: "\e048";
}
.icon-food-129:before {
	content: "\e049";
}
.icon-food-116:before {
	content: "\e04a";
}
.icon-food-117:before {
	content: "\e04b";
}
.icon-food-130:before {
	content: "\e04c";
}
.icon-food-143:before {
	content: "\e04d";
}
.icon-food-142:before {
	content: "\e04e";
}
.icon-food-141:before {
	content: "\e04f";
}
.icon-food-154:before {
	content: "\e050";
}
.icon-food-155:before {
	content: "\e051";
}
.icon-food-156:before {
	content: "\e052";
}
.icon-food-169:before {
	content: "\e053";
}
.icon-food-168:before {
	content: "\e054";
}
.icon-food-167:before {
	content: "\e055";
}
.icon-food-179:before {
	content: "\e056";
}
.icon-food-180:before {
	content: "\e057";
}
.icon-food-181:before {
	content: "\e058";
}
.icon-food-182:before {
	content: "\e059";
}
.icon-food-195:before {
	content: "\e05a";
}
.icon-food-194:before {
	content: "\e05b";
}
.icon-food-193:before {
	content: "\e05c";
}
.icon-food-190:before {
	content: "\e05d";
}
.icon-food-189:before {
	content: "\e05e";
}
.icon-food-188:before {
	content: "\e05f";
}
.icon-food-187:before {
	content: "\e060";
}
.icon-food-185:before {
	content: "\e061";
}
.icon-food-184:before {
	content: "\e062";
}
.icon-food-183:before {
	content: "\e063";
}
.icon-food-186:before {
	content: "\e064";
}
.icon-food-208:before {
	content: "\e065";
}
.icon-food-221:before {
	content: "\e066";
}
.icon-food-220:before {
	content: "\e067";
}
.icon-food-207:before {
	content: "\e068";
}
.icon-food-206:before {
	content: "\e069";
}
.icon-food-219:before {
	content: "\e06a";
}
.icon-food-205:before {
	content: "\e06b";
}
.icon-food-204:before {
	content: "\e06c";
}
.icon-food-217:before {
	content: "\e06d";
}
.icon-food-216:before {
	content: "\e06e";
}
.icon-food-203:before {
	content: "\e06f";
}
.icon-food-202:before {
	content: "\e070";
}
.icon-food-215:before {
	content: "\e071";
}
.icon-food-214:before {
	content: "\e072";
}
.icon-food-201:before {
	content: "\e073";
}
.icon-food-200:before {
	content: "\e074";
}
.icon-food-213:before {
	content: "\e075";
}
.icon-food-212:before {
	content: "\e076";
}
.icon-food-199:before {
	content: "\e077";
}
.icon-food-198:before {
	content: "\e078";
}
.icon-food-211:before {
	content: "\e079";
}
.icon-food-210:before {
	content: "\e07a";
}
.icon-food-197:before {
	content: "\e07b";
}
.icon-food-196:before {
	content: "\e07c";
}
.icon-food-209:before {
	content: "\e07d";
}
.icon-food-222:before {
	content: "\e07e";
}
.icon-food-235:before {
	content: "\e07f";
}
.icon-food-248:before {
	content: "\e080";
}
.icon-food-249:before {
	content: "\e081";
}
.icon-food-236:before {
	content: "\e082";
}
.icon-food-223:before {
	content: "\e083";
}
.icon-food-224:before {
	content: "\e084";
}
.icon-food-237:before {
	content: "\e085";
}
.icon-food-250:before {
	content: "\e086";
}
.icon-food-251:before {
	content: "\e087";
}
.icon-food-238:before {
	content: "\e088";
}
.icon-food-225:before {
	content: "\e089";
}
.icon-food-226:before {
	content: "\e08a";
}
.icon-food-239:before {
	content: "\e08b";
}
.icon-food-252:before {
	content: "\e08c";
}
.icon-food-253:before {
	content: "\e08d";
}
.icon-food-240:before {
	content: "\e08e";
}
.icon-food-227:before {
	content: "\e08f";
}
.icon-food-228:before {
	content: "\e090";
}
.icon-food-241:before {
	content: "\e091";
}
.icon-food-254:before {
	content: "\e092";
}
.icon-food-255:before {
	content: "\e093";
}
.icon-food-242:before {
	content: "\e094";
}
.icon-food-229:before {
	content: "\e095";
}
.icon-food-230:before {
	content: "\e096";
}
.icon-food-243:before {
	content: "\e097";
}
.icon-food-256:before {
	content: "\e098";
}
.icon-food-257:before {
	content: "\e099";
}
.icon-food-244:before {
	content: "\e09a";
}
.icon-food-231:before {
	content: "\e09b";
}
.icon-food-232:before {
	content: "\e09c";
}
.icon-food-245:before {
	content: "\e09d";
}
.icon-food-258:before {
	content: "\e09e";
}
.icon-food-218:before {
	content: "\e09f";
}
.icon-food-233:before {
	content: "\e0a0";
}
.icon-food-246:before {
	content: "\e0a1";
}
.icon-food-259:before {
	content: "\e0a2";
}
.icon-food-260:before {
	content: "\e0a3";
}
.icon-food-247:before {
	content: "\e0a4";
}
.icon-food-234:before {
	content: "\e0a5";
}
.icon-food-273:before {
	content: "\e0a6";
}
.icon-food-286:before {
	content: "\e0a7";
}
.icon-food-299:before {
	content: "\e0a8";
}
.icon-food-298:before {
	content: "\e0a9";
}
.icon-food-285:before {
	content: "\e0aa";
}
.icon-food-272:before {
	content: "\e0ab";
}
.icon-food-271:before {
	content: "\e0ac";
}
.icon-food-284:before {
	content: "\e0ad";
}
.icon-food-297:before {
	content: "\e0ae";
}
.icon-food-296:before {
	content: "\e0af";
}
.icon-food-283:before {
	content: "\e0b0";
}
.icon-food-270:before {
	content: "\e0b1";
}
.icon-food-269:before {
	content: "\e0b2";
}
.icon-food-282:before {
	content: "\e0b3";
}
.icon-food-295:before {
	content: "\e0b4";
}
.icon-food-294:before {
	content: "\e0b5";
}
.icon-food-281:before {
	content: "\e0b6";
}
.icon-food-268:before {
	content: "\e0b7";
}
.icon-food-267:before {
	content: "\e0b8";
}
.icon-food-280:before {
	content: "\e0b9";
}
.icon-food-293:before {
	content: "\e0ba";
}
.icon-food-292:before {
	content: "\e0bb";
}
.icon-food-279:before {
	content: "\e0bc";
}
.icon-food-266:before {
	content: "\e0bd";
}
.icon-food-265:before {
	content: "\e0be";
}
.icon-food-278:before {
	content: "\e0bf";
}
.icon-food-291:before {
	content: "\e0c0";
}
.icon-food-290:before {
	content: "\e0c1";
}
.icon-food-277:before {
	content: "\e0c2";
}
.icon-food-264:before {
	content: "\e0c3";
}
.icon-food-263:before {
	content: "\e0c4";
}
.icon-food-276:before {
	content: "\e0c5";
}
.icon-food-289:before {
	content: "\e0c6";
}
.icon-food-288:before {
	content: "\e0c7";
}
.icon-food-275:before {
	content: "\e0c8";
}
.icon-food-262:before {
	content: "\e0c9";
}
.icon-food-261:before {
	content: "\e0ca";
}
.icon-food-274:before {
	content: "\e0cb";
}
.icon-food-287:before {
	content: "\e0cc";
}
.icon-food-300:before {
	content: "\e0cd";
}

/* Furniture */
@font-face {
	font-family: "furniture";
	src:url("furniture/webfont/fonts/furniture.eot");
	src:url("furniture/webfont/fonts/furniture.eot?#iefix") format("embedded-opentype"),
	url("furniture/webfont/fonts/furniture.woff") format("woff"),
    url("furniture/webfont/fonts/furniture.ttf") format("truetype"),
	url("furniture/webfont/fonts/furniture.svg#furniture") format("svg");
	font-weight: normal;
	font-style: normal;
}

[data-icon-furniture]:before {
	font-family: "furniture" !important;
	content: attr(data-icon-furniture);
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

[class^="icon-furniture"]:before,
[class*=" icon-furniture"]:before {
	font-family: "furniture" !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-furniture-001:before {
	content: "a";
}
.icon-furniture-014:before {
	content: "b";
}
.icon-furniture-027:before {
	content: "c";
}
.icon-furniture-040:before {
	content: "d";
}
.icon-furniture-041:before {
	content: "e";
}
.icon-furniture-028:before {
	content: "f";
}
.icon-furniture-015:before {
	content: "g";
}
.icon-furniture-002:before {
	content: "h";
}
.icon-furniture-003:before {
	content: "i";
}
.icon-furniture-016:before {
	content: "j";
}
.icon-furniture-029:before {
	content: "k";
}
.icon-furniture-042:before {
	content: "l";
}
.icon-furniture-043:before {
	content: "m";
}
.icon-furniture-030:before {
	content: "n";
}
.icon-furniture-017:before {
	content: "o";
}
.icon-furniture-004:before {
	content: "p";
}
.icon-furniture-005:before {
	content: "q";
}
.icon-furniture-018:before {
	content: "r";
}
.icon-furniture-031:before {
	content: "s";
}
.icon-furniture-044:before {
	content: "t";
}
.icon-furniture-045:before {
	content: "u";
}
.icon-furniture-032:before {
	content: "v";
}
.icon-furniture-019:before {
	content: "w";
}
.icon-furniture-006:before {
	content: "x";
}
.icon-furniture-007:before {
	content: "y";
}
.icon-furniture-020:before {
	content: "z";
}
.icon-furniture-033:before {
	content: "A";
}
.icon-furniture-046:before {
	content: "B";
}
.icon-furniture-047:before {
	content: "C";
}
.icon-furniture-034:before {
	content: "D";
}
.icon-furniture-021:before {
	content: "E";
}
.icon-furniture-008:before {
	content: "F";
}
.icon-furniture-009:before {
	content: "G";
}
.icon-furniture-022:before {
	content: "H";
}
.icon-furniture-035:before {
	content: "I";
}
.icon-furniture-048:before {
	content: "J";
}
.icon-furniture-049:before {
	content: "K";
}
.icon-furniture-036:before {
	content: "L";
}
.icon-furniture-023:before {
	content: "M";
}
.icon-furniture-010:before {
	content: "N";
}
.icon-furniture-011:before {
	content: "O";
}
.icon-furniture-024:before {
	content: "P";
}
.icon-furniture-037:before {
	content: "Q";
}
.icon-furniture-050:before {
	content: "R";
}
.icon-furniture-051:before {
	content: "S";
}
.icon-furniture-038:before {
	content: "T";
}
.icon-furniture-025:before {
	content: "U";
}
.icon-furniture-012:before {
	content: "V";
}
.icon-furniture-013:before {
	content: "W";
}
.icon-furniture-026:before {
	content: "X";
}
.icon-furniture-039:before {
	content: "Y";
}
.icon-furniture-052:before {
	content: "Z";
}
.icon-furniture-065:before {
	content: "0";
}
.icon-furniture-064:before {
	content: "1";
}
.icon-furniture-063:before {
	content: "2";
}
.icon-furniture-062:before {
	content: "3";
}
.icon-furniture-061:before {
	content: "4";
}
.icon-furniture-060:before {
	content: "5";
}
.icon-furniture-059:before {
	content: "6";
}
.icon-furniture-058:before {
	content: "7";
}
.icon-furniture-057:before {
	content: "8";
}
.icon-furniture-056:before {
	content: "9";
}
.icon-furniture-055:before {
	content: "!";
}
.icon-furniture-054:before {
	content: "\"";
}
.icon-furniture-053:before {
	content: "#";
}
.icon-furniture-066:before {
	content: "$";
}
.icon-furniture-079:before {
	content: "%";
}
.icon-furniture-092:before {
	content: "&";
}
.icon-furniture-093:before {
	content: "'";
}
.icon-furniture-080:before {
	content: "(";
}
.icon-furniture-067:before {
	content: ")";
}
.icon-furniture-068:before {
	content: "*";
}
.icon-furniture-081:before {
	content: "+";
}
.icon-furniture-094:before {
	content: ",";
}
.icon-furniture-095:before {
	content: "-";
}
.icon-furniture-082:before {
	content: ".";
}
.icon-furniture-069:before {
	content: "/";
}
.icon-furniture-070:before {
	content: ":";
}
.icon-furniture-083:before {
	content: ";";
}
.icon-furniture-096:before {
	content: "<";
}
.icon-furniture-097:before {
	content: "=";
}
.icon-furniture-084:before {
	content: ">";
}
.icon-furniture-071:before {
	content: "?";
}
.icon-furniture-073:before {
	content: "@";
}
.icon-furniture-072:before {
	content: "[";
}
.icon-furniture-085:before {
	content: "]";
}
.icon-furniture-098:before {
	content: "^";
}
.icon-furniture-099:before {
	content: "_";
}
.icon-furniture-086:before {
	content: "`";
}
.icon-furniture-087:before {
	content: "{";
}
.icon-furniture-100:before {
	content: "|";
}
.icon-furniture-075:before {
	content: "}";
}
.icon-furniture-074:before {
	content: "~";
}
.icon-furniture-076:before {
	content: "\\";
}
.icon-furniture-089:before {
	content: "\e000";
}
.icon-furniture-088:before {
	content: "\e001";
}
.icon-furniture-090:before {
	content: "\e002";
}
.icon-furniture-077:before {
	content: "\e003";
}
.icon-furniture-078:before {
	content: "\e004";
}
.icon-furniture-091:before {
	content: "\e005";
}


@charset "UTF-8";

@font-face {
	font-family: "hotel-restaurant";
	src:url("hotel-restaurant/webfont/fonts/hotel-restaurant.eot");
	src:url("hotel-restaurant/webfont/fonts/hotel-restaurant.eot?#iefix") format("embedded-opentype"),
	url("hotel-restaurant/webfont/fonts/hotel-restaurant.woff") format("woff"),
    url("hotel-restaurant/webfont/fonts/hotel-restaurant.ttf") format("truetype"),
	url("hotel-restaurant/webfont/fonts/hotel-restaurant.svg#hotel-restaurant") format("svg");
	font-weight: normal;
	font-style: normal;
}

[data-icon-hotel-restaurant]:before {
	font-family: "hotel-restaurant" !important;
	content: attr(data-icon-hotel-restaurant);
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

[class^="icon-hotel-restaurant"]:before,
[class*=" icon-hotel-restaurant"]:before {
	font-family: "hotel-restaurant" !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-hotel-restaurant-001:before {
	content: "a";
}
.icon-hotel-restaurant-002:before {
	content: "b";
}
.icon-hotel-restaurant-003:before {
	content: "c";
}
.icon-hotel-restaurant-004:before {
	content: "d";
}
.icon-hotel-restaurant-005:before {
	content: "e";
}
.icon-hotel-restaurant-006:before {
	content: "f";
}
.icon-hotel-restaurant-007:before {
	content: "g";
}
.icon-hotel-restaurant-008:before {
	content: "h";
}
.icon-hotel-restaurant-009:before {
	content: "i";
}
.icon-hotel-restaurant-010:before {
	content: "j";
}
.icon-hotel-restaurant-011:before {
	content: "k";
}
.icon-hotel-restaurant-065:before {
	content: "l";
}
.icon-hotel-restaurant-012:before {
	content: "m";
}
.icon-hotel-restaurant-013:before {
	content: "n";
}
.icon-hotel-restaurant-026:before {
	content: "o";
}
.icon-hotel-restaurant-025:before {
	content: "p";
}
.icon-hotel-restaurant-024:before {
	content: "q";
}
.icon-hotel-restaurant-023:before {
	content: "r";
}
.icon-hotel-restaurant-022:before {
	content: "s";
}
.icon-hotel-restaurant-021:before {
	content: "t";
}
.icon-hotel-restaurant-020:before {
	content: "u";
}
.icon-hotel-restaurant-019:before {
	content: "v";
}
.icon-hotel-restaurant-018:before {
	content: "w";
}
.icon-hotel-restaurant-017:before {
	content: "x";
}
.icon-hotel-restaurant-016:before {
	content: "y";
}
.icon-hotel-restaurant-015:before {
	content: "z";
}
.icon-hotel-restaurant-014:before {
	content: "A";
}
.icon-hotel-restaurant-027:before {
	content: "B";
}
.icon-hotel-restaurant-028:before {
	content: "C";
}
.icon-hotel-restaurant-029:before {
	content: "D";
}
.icon-hotel-restaurant-030:before {
	content: "E";
}
.icon-hotel-restaurant-031:before {
	content: "F";
}
.icon-hotel-restaurant-032:before {
	content: "G";
}
.icon-hotel-restaurant-033:before {
	content: "H";
}
.icon-hotel-restaurant-034:before {
	content: "I";
}
.icon-hotel-restaurant-035:before {
	content: "J";
}
.icon-hotel-restaurant-036:before {
	content: "K";
}
.icon-hotel-restaurant-038:before {
	content: "L";
}
.icon-hotel-restaurant-039:before {
	content: "M";
}
.icon-hotel-restaurant-037:before {
	content: "N";
}
.icon-hotel-restaurant-052:before {
	content: "O";
}
.icon-hotel-restaurant-051:before {
	content: "P";
}
.icon-hotel-restaurant-050:before {
	content: "Q";
}
.icon-hotel-restaurant-049:before {
	content: "R";
}
.icon-hotel-restaurant-048:before {
	content: "S";
}
.icon-hotel-restaurant-047:before {
	content: "T";
}
.icon-hotel-restaurant-046:before {
	content: "U";
}
.icon-hotel-restaurant-045:before {
	content: "V";
}
.icon-hotel-restaurant-044:before {
	content: "W";
}
.icon-hotel-restaurant-042:before {
	content: "X";
}
.icon-hotel-restaurant-041:before {
	content: "Y";
}
.icon-hotel-restaurant-040:before {
	content: "Z";
}
.icon-hotel-restaurant-043:before {
	content: "0";
}
.icon-hotel-restaurant-053:before {
	content: "1";
}
.icon-hotel-restaurant-054:before {
	content: "2";
}
.icon-hotel-restaurant-055:before {
	content: "3";
}
.icon-hotel-restaurant-056:before {
	content: "4";
}
.icon-hotel-restaurant-057:before {
	content: "5";
}
.icon-hotel-restaurant-058:before {
	content: "6";
}
.icon-hotel-restaurant-059:before {
	content: "7";
}
.icon-hotel-restaurant-060:before {
	content: "8";
}
.icon-hotel-restaurant-061:before {
	content: "9";
}
.icon-hotel-restaurant-062:before {
	content: "!";
}
.icon-hotel-restaurant-063:before {
	content: "\"";
}
.icon-hotel-restaurant-064:before {
	content: "#";
}
.icon-hotel-restaurant-066:before {
	content: "$";
}
.icon-hotel-restaurant-079:before {
	content: "%";
}
.icon-hotel-restaurant-092:before {
	content: "&";
}
.icon-hotel-restaurant-105:before {
	content: "'";
}
.icon-hotel-restaurant-118:before {
	content: "(";
}
.icon-hotel-restaurant-131:before {
	content: ")";
}
.icon-hotel-restaurant-144:before {
	content: "*";
}
.icon-hotel-restaurant-157:before {
	content: "+";
}
.icon-hotel-restaurant-067:before {
	content: ",";
}
.icon-hotel-restaurant-080:before {
	content: "-";
}
.icon-hotel-restaurant-068:before {
	content: ".";
}
.icon-hotel-restaurant-081:before {
	content: "/";
}
.icon-hotel-restaurant-093:before {
	content: ":";
}
.icon-hotel-restaurant-094:before {
	content: ";";
}
.icon-hotel-restaurant-095:before {
	content: "<";
}
.icon-hotel-restaurant-096:before {
	content: "=";
}
.icon-hotel-restaurant-097:before {
	content: ">";
}
.icon-hotel-restaurant-098:before {
	content: "?";
}
.icon-hotel-restaurant-099:before {
	content: "@";
}
.icon-hotel-restaurant-100:before {
	content: "[";
}
.icon-hotel-restaurant-101:before {
	content: "]";
}
.icon-hotel-restaurant-102:before {
	content: "^";
}
.icon-hotel-restaurant-103:before {
	content: "_";
}
.icon-hotel-restaurant-104:before {
	content: "`";
}
.icon-hotel-restaurant-091:before {
	content: "{";
}
.icon-hotel-restaurant-090:before {
	content: "|";
}
.icon-hotel-restaurant-089:before {
	content: "}";
}
.icon-hotel-restaurant-088:before {
	content: "~";
}
.icon-hotel-restaurant-087:before {
	content: "\\";
}
.icon-hotel-restaurant-086:before {
	content: "\e000";
}
.icon-hotel-restaurant-085:before {
	content: "\e001";
}
.icon-hotel-restaurant-084:before {
	content: "\e002";
}
.icon-hotel-restaurant-083:before {
	content: "\e003";
}
.icon-hotel-restaurant-082:before {
	content: "\e004";
}
.icon-hotel-restaurant-069:before {
	content: "\e005";
}
.icon-hotel-restaurant-070:before {
	content: "\e006";
}
.icon-hotel-restaurant-071:before {
	content: "\e007";
}
.icon-hotel-restaurant-072:before {
	content: "\e008";
}
.icon-hotel-restaurant-073:before {
	content: "\e009";
}
.icon-hotel-restaurant-074:before {
	content: "\e00a";
}
.icon-hotel-restaurant-075:before {
	content: "\e00b";
}
.icon-hotel-restaurant-076:before {
	content: "\e00c";
}
.icon-hotel-restaurant-077:before {
	content: "\e00d";
}
.icon-hotel-restaurant-078:before {
	content: "\e00e";
}
.icon-hotel-restaurant-117:before {
	content: "\e00f";
}
.icon-hotel-restaurant-116:before {
	content: "\e010";
}
.icon-hotel-restaurant-115:before {
	content: "\e011";
}
.icon-hotel-restaurant-114:before {
	content: "\e012";
}
.icon-hotel-restaurant-113:before {
	content: "\e013";
}
.icon-hotel-restaurant-112:before {
	content: "\e014";
}
.icon-hotel-restaurant-111:before {
	content: "\e015";
}
.icon-hotel-restaurant-110:before {
	content: "\e016";
}
.icon-hotel-restaurant-109:before {
	content: "\e017";
}
.icon-hotel-restaurant-108:before {
	content: "\e018";
}
.icon-hotel-restaurant-107:before {
	content: "\e019";
}
.icon-hotel-restaurant-106:before {
	content: "\e01a";
}
.icon-hotel-restaurant-119:before {
	content: "\e01b";
}
.icon-hotel-restaurant-120:before {
	content: "\e01c";
}
.icon-hotel-restaurant-121:before {
	content: "\e01d";
}
.icon-hotel-restaurant-122:before {
	content: "\e01e";
}
.icon-hotel-restaurant-123:before {
	content: "\e01f";
}
.icon-hotel-restaurant-124:before {
	content: "\e020";
}
.icon-hotel-restaurant-125:before {
	content: "\e021";
}
.icon-hotel-restaurant-126:before {
	content: "\e022";
}
.icon-hotel-restaurant-127:before {
	content: "\e023";
}
.icon-hotel-restaurant-128:before {
	content: "\e024";
}
.icon-hotel-restaurant-129:before {
	content: "\e025";
}
.icon-hotel-restaurant-130:before {
	content: "\e026";
}
.icon-hotel-restaurant-143:before {
	content: "\e027";
}
.icon-hotel-restaurant-142:before {
	content: "\e028";
}
.icon-hotel-restaurant-141:before {
	content: "\e029";
}
.icon-hotel-restaurant-140:before {
	content: "\e02a";
}
.icon-hotel-restaurant-139:before {
	content: "\e02b";
}
.icon-hotel-restaurant-138:before {
	content: "\e02c";
}
.icon-hotel-restaurant-137:before {
	content: "\e02d";
}
.icon-hotel-restaurant-136:before {
	content: "\e02e";
}
.icon-hotel-restaurant-135:before {
	content: "\e02f";
}
.icon-hotel-restaurant-134:before {
	content: "\e030";
}
.icon-hotel-restaurant-133:before {
	content: "\e031";
}
.icon-hotel-restaurant-132:before {
	content: "\e032";
}
.icon-hotel-restaurant-145:before {
	content: "\e033";
}
.icon-hotel-restaurant-146:before {
	content: "\e034";
}
.icon-hotel-restaurant-147:before {
	content: "\e035";
}
.icon-hotel-restaurant-148:before {
	content: "\e036";
}
.icon-hotel-restaurant-149:before {
	content: "\e037";
}
.icon-hotel-restaurant-150:before {
	content: "\e038";
}
.icon-hotel-restaurant-151:before {
	content: "\e039";
}
.icon-hotel-restaurant-152:before {
	content: "\e03a";
}
.icon-hotel-restaurant-153:before {
	content: "\e03b";
}
.icon-hotel-restaurant-154:before {
	content: "\e03c";
}
.icon-hotel-restaurant-155:before {
	content: "\e03d";
}
.icon-hotel-restaurant-156:before {
	content: "\e03e";
}
.icon-hotel-restaurant-169:before {
	content: "\e03f";
}
.icon-hotel-restaurant-168:before {
	content: "\e040";
}
.icon-hotel-restaurant-167:before {
	content: "\e041";
}
.icon-hotel-restaurant-166:before {
	content: "\e042";
}
.icon-hotel-restaurant-165:before {
	content: "\e043";
}
.icon-hotel-restaurant-164:before {
	content: "\e044";
}
.icon-hotel-restaurant-163:before {
	content: "\e045";
}
.icon-hotel-restaurant-162:before {
	content: "\e046";
}
.icon-hotel-restaurant-161:before {
	content: "\e047";
}
.icon-hotel-restaurant-160:before {
	content: "\e048";
}
.icon-hotel-restaurant-159:before {
	content: "\e049";
}
.icon-hotel-restaurant-158:before {
	content: "\e04a";
}
.icon-hotel-restaurant-170:before {
	content: "\e04b";
}
.icon-hotel-restaurant-171:before {
	content: "\e04c";
}
.icon-hotel-restaurant-172:before {
	content: "\e04d";
}
.icon-hotel-restaurant-173:before {
	content: "\e04e";
}
.icon-hotel-restaurant-174:before {
	content: "\e04f";
}
.icon-hotel-restaurant-175:before {
	content: "\e050";
}
.icon-hotel-restaurant-176:before {
	content: "\e051";
}
.icon-hotel-restaurant-177:before {
	content: "\e052";
}
.icon-hotel-restaurant-178:before {
	content: "\e053";
}
.icon-hotel-restaurant-179:before {
	content: "\e054";
}
.icon-hotel-restaurant-180:before {
	content: "\e055";
}
.icon-hotel-restaurant-181:before {
	content: "\e056";
}
.icon-hotel-restaurant-182:before {
	content: "\e057";
}
.icon-hotel-restaurant-195:before {
	content: "\e058";
}
.icon-hotel-restaurant-194:before {
	content: "\e059";
}
.icon-hotel-restaurant-193:before {
	content: "\e05a";
}
.icon-hotel-restaurant-192:before {
	content: "\e05b";
}
.icon-hotel-restaurant-191:before {
	content: "\e05c";
}
.icon-hotel-restaurant-190:before {
	content: "\e05d";
}
.icon-hotel-restaurant-189:before {
	content: "\e05e";
}
.icon-hotel-restaurant-188:before {
	content: "\e05f";
}
.icon-hotel-restaurant-187:before {
	content: "\e060";
}
.icon-hotel-restaurant-186:before {
	content: "\e061";
}
.icon-hotel-restaurant-185:before {
	content: "\e062";
}
.icon-hotel-restaurant-184:before {
	content: "\e063";
}
.icon-hotel-restaurant-183:before {
	content: "\e064";
}
.icon-hotel-restaurant-196:before {
	content: "\e065";
}
.icon-hotel-restaurant-197:before {
	content: "\e066";
}
.icon-hotel-restaurant-198:before {
	content: "\e067";
}
.icon-hotel-restaurant-199:before {
	content: "\e068";
}
.icon-hotel-restaurant-200:before {
	content: "\e069";
}
.icon-hotel-restaurant-201:before {
	content: "\e06a";
}
.icon-hotel-restaurant-202:before {
	content: "\e06b";
}
.icon-hotel-restaurant-203:before {
	content: "\e06c";
}
.icon-hotel-restaurant-204:before {
	content: "\e06d";
}
.icon-hotel-restaurant-205:before {
	content: "\e06e";
}
.icon-hotel-restaurant-206:before {
	content: "\e06f";
}
.icon-hotel-restaurant-207:before {
	content: "\e070";
}
.icon-hotel-restaurant-208:before {
	content: "\e071";
}
.icon-hotel-restaurant-221:before {
	content: "\e072";
}
.icon-hotel-restaurant-220:before {
	content: "\e073";
}
.icon-hotel-restaurant-219:before {
	content: "\e074";
}
.icon-hotel-restaurant-218:before {
	content: "\e075";
}
.icon-hotel-restaurant-217:before {
	content: "\e076";
}
.icon-hotel-restaurant-216:before {
	content: "\e077";
}
.icon-hotel-restaurant-215:before {
	content: "\e078";
}
.icon-hotel-restaurant-214:before {
	content: "\e079";
}
.icon-hotel-restaurant-213:before {
	content: "\e07a";
}
.icon-hotel-restaurant-212:before {
	content: "\e07b";
}
.icon-hotel-restaurant-211:before {
	content: "\e07c";
}
.icon-hotel-restaurant-210:before {
	content: "\e07d";
}
.icon-hotel-restaurant-209:before {
	content: "\e07e";
}
.icon-hotel-restaurant-222:before {
	content: "\e07f";
}
.icon-hotel-restaurant-223:before {
	content: "\e080";
}
.icon-hotel-restaurant-224:before {
	content: "\e081";
}
.icon-hotel-restaurant-225:before {
	content: "\e082";
}
.icon-hotel-restaurant-226:before {
	content: "\e083";
}
.icon-hotel-restaurant-227:before {
	content: "\e084";
}
.icon-hotel-restaurant-228:before {
	content: "\e085";
}
.icon-hotel-restaurant-229:before {
	content: "\e086";
}
.icon-hotel-restaurant-230:before {
	content: "\e087";
}
.icon-hotel-restaurant-231:before {
	content: "\e088";
}
.icon-hotel-restaurant-232:before {
	content: "\e089";
}
.icon-hotel-restaurant-233:before {
	content: "\e08a";
}
.icon-hotel-restaurant-234:before {
	content: "\e08b";
}
.icon-hotel-restaurant-247:before {
	content: "\e08c";
}
.icon-hotel-restaurant-246:before {
	content: "\e08d";
}
.icon-hotel-restaurant-245:before {
	content: "\e08e";
}
.icon-hotel-restaurant-244:before {
	content: "\e08f";
}
.icon-hotel-restaurant-243:before {
	content: "\e090";
}
.icon-hotel-restaurant-242:before {
	content: "\e091";
}
.icon-hotel-restaurant-241:before {
	content: "\e092";
}
.icon-hotel-restaurant-240:before {
	content: "\e093";
}
.icon-hotel-restaurant-239:before {
	content: "\e094";
}
.icon-hotel-restaurant-238:before {
	content: "\e095";
}
.icon-hotel-restaurant-237:before {
	content: "\e096";
}
.icon-hotel-restaurant-236:before {
	content: "\e097";
}
.icon-hotel-restaurant-235:before {
	content: "\e098";
}
.icon-hotel-restaurant-248:before {
	content: "\e099";
}
.icon-hotel-restaurant-249:before {
	content: "\e09a";
}
.icon-hotel-restaurant-250:before {
	content: "\e09b";
}

@charset "UTF-8";

@font-face {
	font-family: "media";
	src:url("media/webfont/fonts/media.eot");
	src:url("media/webfont/fonts/media.eot?#iefix") format("embedded-opentype"),
	url("media/webfont/fonts/media.woff") format("woff"),
    url("media/webfont/fonts/media.ttf") format("truetype"),
	url("media/webfont/fonts/media.svg#media") format("svg");
	font-weight: normal;
	font-style: normal;
}

[data-icon-media]:before {
	font-family: "media" !important;
	content: attr(data-icon-media);
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

[class^="icon-media"]:before,
[class*=" icon-media"]:before {
	font-family: "media" !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-media-001:before {
	content: "a";
}
.icon-media-014:before {
	content: "b";
}
.icon-media-027:before {
	content: "c";
}
.icon-media-040:before {
	content: "d";
}
.icon-media-041:before {
	content: "e";
}
.icon-media-028:before {
	content: "f";
}
.icon-media-015:before {
	content: "g";
}
.icon-media-002:before {
	content: "h";
}
.icon-media-003:before {
	content: "i";
}
.icon-media-016:before {
	content: "j";
}
.icon-media-029:before {
	content: "k";
}
.icon-media-042:before {
	content: "l";
}
.icon-media-043:before {
	content: "m";
}
.icon-media-030:before {
	content: "n";
}
.icon-media-017:before {
	content: "o";
}
.icon-media-004:before {
	content: "p";
}
.icon-media-005:before {
	content: "q";
}
.icon-media-018:before {
	content: "r";
}
.icon-media-031:before {
	content: "s";
}
.icon-media-044:before {
	content: "t";
}
.icon-media-045:before {
	content: "u";
}
.icon-media-032:before {
	content: "v";
}
.icon-media-019:before {
	content: "w";
}
.icon-media-006:before {
	content: "x";
}
.icon-media-007:before {
	content: "y";
}
.icon-media-020:before {
	content: "z";
}
.icon-media-033:before {
	content: "A";
}
.icon-media-046:before {
	content: "B";
}
.icon-media-047:before {
	content: "C";
}
.icon-media-034:before {
	content: "D";
}
.icon-media-021:before {
	content: "E";
}
.icon-media-008:before {
	content: "F";
}
.icon-media-009:before {
	content: "G";
}
.icon-media-022:before {
	content: "H";
}
.icon-media-035:before {
	content: "I";
}
.icon-media-048:before {
	content: "J";
}
.icon-media-049:before {
	content: "K";
}
.icon-media-036:before {
	content: "L";
}
.icon-media-023:before {
	content: "M";
}
.icon-media-010:before {
	content: "N";
}
.icon-media-011:before {
	content: "O";
}
.icon-media-024:before {
	content: "P";
}
.icon-media-037:before {
	content: "Q";
}
.icon-media-050:before {
	content: "R";
}
.icon-media-063:before {
	content: "S";
}
.icon-media-064:before {
	content: "T";
}
.icon-media-051:before {
	content: "U";
}
.icon-media-038:before {
	content: "V";
}
.icon-media-025:before {
	content: "W";
}
.icon-media-012:before {
	content: "X";
}
.icon-media-013:before {
	content: "Y";
}
.icon-media-026:before {
	content: "Z";
}
.icon-media-039:before {
	content: "0";
}
.icon-media-052:before {
	content: "1";
}
.icon-media-065:before {
	content: "2";
}
.icon-media-062:before {
	content: "3";
}
.icon-media-061:before {
	content: "4";
}
.icon-media-060:before {
	content: "5";
}
.icon-media-059:before {
	content: "6";
}
.icon-media-058:before {
	content: "7";
}
.icon-media-057:before {
	content: "8";
}
.icon-media-056:before {
	content: "9";
}
.icon-media-055:before {
	content: "!";
}
.icon-media-054:before {
	content: "\"";
}
.icon-media-053:before {
	content: "#";
}
.icon-media-066:before {
	content: "$";
}
.icon-media-079:before {
	content: "%";
}
.icon-media-092:before {
	content: "&";
}
.icon-media-105:before {
	content: "'";
}
.icon-media-118:before {
	content: "(";
}
.icon-media-119:before {
	content: ")";
}
.icon-media-106:before {
	content: "*";
}
.icon-media-093:before {
	content: "+";
}
.icon-media-080:before {
	content: ",";
}
.icon-media-067:before {
	content: "-";
}
.icon-media-068:before {
	content: ".";
}
.icon-media-081:before {
	content: "/";
}
.icon-media-094:before {
	content: ":";
}
.icon-media-107:before {
	content: ";";
}
.icon-media-120:before {
	content: "<";
}
.icon-media-121:before {
	content: "=";
}
.icon-media-108:before {
	content: ">";
}
.icon-media-095:before {
	content: "?";
}
.icon-media-082:before {
	content: "@";
}
.icon-media-069:before {
	content: "[";
}
.icon-media-070:before {
	content: "]";
}
.icon-media-083:before {
	content: "^";
}
.icon-media-096:before {
	content: "_";
}
.icon-media-109:before {
	content: "`";
}
.icon-media-122:before {
	content: "{";
}
.icon-media-123:before {
	content: "|";
}
.icon-media-110:before {
	content: "}";
}
.icon-media-097:before {
	content: "~";
}
.icon-media-084:before {
	content: "\\";
}
.icon-media-071:before {
	content: "\e000";
}
.icon-media-072:before {
	content: "\e001";
}
.icon-media-085:before {
	content: "\e002";
}
.icon-media-098:before {
	content: "\e003";
}
.icon-media-111:before {
	content: "\e004";
}
.icon-media-124:before {
	content: "\e005";
}
.icon-media-125:before {
	content: "\e006";
}
.icon-media-112:before {
	content: "\e007";
}
.icon-media-099:before {
	content: "\e008";
}
.icon-media-086:before {
	content: "\e009";
}
.icon-media-073:before {
	content: "\e00a";
}
.icon-media-074:before {
	content: "\e00b";
}
.icon-media-087:before {
	content: "\e00c";
}
.icon-media-100:before {
	content: "\e00d";
}
.icon-media-113:before {
	content: "\e00e";
}
.icon-media-126:before {
	content: "\e00f";
}
.icon-media-127:before {
	content: "\e010";
}
.icon-media-114:before {
	content: "\e011";
}
.icon-media-101:before {
	content: "\e012";
}
.icon-media-088:before {
	content: "\e013";
}
.icon-media-075:before {
	content: "\e014";
}
.icon-media-076:before {
	content: "\e015";
}
.icon-media-089:before {
	content: "\e016";
}
.icon-media-090:before {
	content: "\e017";
}
.icon-media-077:before {
	content: "\e018";
}
.icon-media-078:before {
	content: "\e019";
}
.icon-media-091:before {
	content: "\e01a";
}
.icon-media-104:before {
	content: "\e01b";
}
.icon-media-103:before {
	content: "\e01c";
}
.icon-media-102:before {
	content: "\e01d";
}
.icon-media-115:before {
	content: "\e01e";
}
.icon-media-116:before {
	content: "\e01f";
}
.icon-media-117:before {
	content: "\e020";
}
.icon-media-130:before {
	content: "\e021";
}
.icon-media-129:before {
	content: "\e022";
}
.icon-media-128:before {
	content: "\e023";
}

/* medical-and-health */
@font-face {
	font-family: "medical-and-health";
	src:url("medical/webfont/fonts/medical-and-health.eot");
	src:url("medical/webfont/fonts/medical-and-health.eot?#iefix") format("embedded-opentype"),
	url("medical/webfont/fonts/medical-and-health.woff") format("woff"),
    url("medical/webfont/fonts/medical-and-health.ttf") format("truetype"),
	url("medical/webfont/fonts/medical-and-health.svg#medical-and-health") format("svg");
	font-weight: normal;
	font-style: normal;
}

[data-icon-medical]:before {
	font-family: "medical-and-health" !important;
	content: attr(data-icon-medical);
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

[class^="icon-medical"]:before,
[class*=" icon-medical"]:before {
	font-family: "medical-and-health" !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-medical-001:before {
	content: "a";
}
.icon-medical-002:before {
	content: "b";
}
.icon-medical-015:before {
	content: "c";
}
.icon-medical-014:before {
	content: "d";
}
.icon-medical-027:before {
	content: "e";
}
.icon-medical-028:before {
	content: "f";
}
.icon-medical-029:before {
	content: "g";
}
.icon-medical-016:before {
	content: "h";
}
.icon-medical-003:before {
	content: "i";
}
.icon-medical-004:before {
	content: "j";
}
.icon-medical-017:before {
	content: "k";
}
.icon-medical-030:before {
	content: "l";
}
.icon-medical-031:before {
	content: "m";
}
.icon-medical-018:before {
	content: "n";
}
.icon-medical-005:before {
	content: "o";
}
.icon-medical-006:before {
	content: "p";
}
.icon-medical-019:before {
	content: "q";
}
.icon-medical-032:before {
	content: "r";
}
.icon-medical-033:before {
	content: "s";
}
.icon-medical-020:before {
	content: "t";
}
.icon-medical-007:before {
	content: "u";
}
.icon-medical-008:before {
	content: "v";
}
.icon-medical-021:before {
	content: "w";
}
.icon-medical-034:before {
	content: "x";
}
.icon-medical-035:before {
	content: "y";
}
.icon-medical-022:before {
	content: "z";
}
.icon-medical-009:before {
	content: "A";
}
.icon-medical-010:before {
	content: "B";
}
.icon-medical-023:before {
	content: "C";
}
.icon-medical-036:before {
	content: "D";
}
.icon-medical-037:before {
	content: "E";
}
.icon-medical-024:before {
	content: "F";
}
.icon-medical-011:before {
	content: "G";
}
.icon-medical-012:before {
	content: "H";
}
.icon-medical-025:before {
	content: "I";
}
.icon-medical-038:before {
	content: "J";
}
.icon-medical-039:before {
	content: "K";
}
.icon-medical-026:before {
	content: "L";
}
.icon-medical-013:before {
	content: "M";
}
.icon-medical-040:before {
	content: "N";
}
.icon-medical-053:before {
	content: "O";
}
.icon-medical-066:before {
	content: "P";
}
.icon-medical-079:before {
	content: "Q";
}
.icon-medical-092:before {
	content: "R";
}
.icon-medical-093:before {
	content: "S";
}
.icon-medical-080:before {
	content: "T";
}
.icon-medical-067:before {
	content: "U";
}
.icon-medical-054:before {
	content: "V";
}
.icon-medical-041:before {
	content: "W";
}
.icon-medical-042:before {
	content: "X";
}
.icon-medical-055:before {
	content: "Y";
}
.icon-medical-068:before {
	content: "Z";
}
.icon-medical-081:before {
	content: "0";
}
.icon-medical-094:before {
	content: "1";
}
.icon-medical-096:before {
	content: "2";
}
.icon-medical-082:before {
	content: "3";
}
.icon-medical-095:before {
	content: "4";
}
.icon-medical-069:before {
	content: "5";
}
.icon-medical-056:before {
	content: "6";
}
.icon-medical-043:before {
	content: "7";
}
.icon-medical-044:before {
	content: "8";
}
.icon-medical-057:before {
	content: "9";
}
.icon-medical-070:before {
	content: "!";
}
.icon-medical-083:before {
	content: "\"";
}
.icon-medical-084:before {
	content: "#";
}
.icon-medical-071:before {
	content: "$";
}
.icon-medical-058:before {
	content: "%";
}
.icon-medical-045:before {
	content: "&";
}
.icon-medical-046:before {
	content: "'";
}
.icon-medical-059:before {
	content: "(";
}
.icon-medical-098:before {
	content: ")";
}
.icon-medical-097:before {
	content: "*";
}
.icon-medical-085:before {
	content: "+";
}
.icon-medical-072:before {
	content: ",";
}
.icon-medical-073:before {
	content: "-";
}
.icon-medical-086:before {
	content: ".";
}
.icon-medical-099:before {
	content: "/";
}
.icon-medical-100:before {
	content: ":";
}
.icon-medical-087:before {
	content: ";";
}
.icon-medical-074:before {
	content: "<";
}
.icon-medical-060:before {
	content: "=";
}
.icon-medical-061:before {
	content: ">";
}
.icon-medical-047:before {
	content: "?";
}
.icon-medical-048:before {
	content: "@";
}
.icon-medical-049:before {
	content: "[";
}
.icon-medical-062:before {
	content: "]";
}
.icon-medical-075:before {
	content: "^";
}
.icon-medical-088:before {
	content: "_";
}
.icon-medical-089:before {
	content: "`";
}
.icon-medical-076:before {
	content: "{";
}
.icon-medical-063:before {
	content: "|";
}
.icon-medical-050:before {
	content: "}";
}
.icon-medical-051:before {
	content: "~";
}
.icon-medical-064:before {
	content: "\\";
}
.icon-medical-077:before {
	content: "\e000";
}
.icon-medical-090:before {
	content: "\e001";
}
.icon-medical-091:before {
	content: "\e002";
}
.icon-medical-078:before {
	content: "\e003";
}
.icon-medical-065:before {
	content: "\e004";
}
.icon-medical-052:before {
	content: "\e005";
}

/* Music */
@font-face {
	font-family: "music";
	src:url("music/webfont/fonts/music.eot");
	src:url("music/webfont/fonts/music.eot?#iefix") format("embedded-opentype"),
	url("music/webfont/fonts/music.woff") format("woff"),
    url("music/webfont/fonts/music.ttf") format("truetype"),
	url("music/webfont/fonts/music.svg#music") format("svg");
	font-weight: normal;
	font-style: normal;
}

[data-icon-music]:before {
	font-family: "music" !important;
	content: attr(data-icon-music);
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

[class^="icon-music"]:before,
[class*=" icon-music"]:before {
	font-family: "music" !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-music-001:before {
	content: "a";
}
.icon-music-014:before {
	content: "b";
}
.icon-music-027:before {
	content: "c";
}
.icon-music-040:before {
	content: "d";
}
.icon-music-053:before {
	content: "e";
}
.icon-music-054:before {
	content: "f";
}
.icon-music-041:before {
	content: "g";
}
.icon-music-028:before {
	content: "h";
}
.icon-music-015:before {
	content: "i";
}
.icon-music-002:before {
	content: "j";
}
.icon-music-003:before {
	content: "k";
}
.icon-music-016:before {
	content: "l";
}
.icon-music-029:before {
	content: "m";
}
.icon-music-042:before {
	content: "n";
}
.icon-music-055:before {
	content: "o";
}
.icon-music-056:before {
	content: "p";
}
.icon-music-043:before {
	content: "q";
}
.icon-music-030:before {
	content: "r";
}
.icon-music-017:before {
	content: "s";
}
.icon-music-004:before {
	content: "t";
}
.icon-music-005:before {
	content: "u";
}
.icon-music-018:before {
	content: "v";
}
.icon-music-031:before {
	content: "w";
}
.icon-music-044:before {
	content: "x";
}
.icon-music-057:before {
	content: "y";
}
.icon-music-058:before {
	content: "z";
}
.icon-music-045:before {
	content: "A";
}
.icon-music-032:before {
	content: "B";
}
.icon-music-019:before {
	content: "C";
}
.icon-music-006:before {
	content: "D";
}
.icon-music-007:before {
	content: "E";
}
.icon-music-020:before {
	content: "F";
}
.icon-music-033:before {
	content: "G";
}
.icon-music-046:before {
	content: "H";
}
.icon-music-059:before {
	content: "I";
}
.icon-music-060:before {
	content: "J";
}
.icon-music-047:before {
	content: "K";
}
.icon-music-034:before {
	content: "L";
}
.icon-music-021:before {
	content: "M";
}
.icon-music-008:before {
	content: "N";
}
.icon-music-009:before {
	content: "O";
}
.icon-music-022:before {
	content: "P";
}
.icon-music-035:before {
	content: "Q";
}
.icon-music-048:before {
	content: "R";
}
.icon-music-061:before {
	content: "S";
}
.icon-music-062:before {
	content: "T";
}
.icon-music-036:before {
	content: "U";
}
.icon-music-023:before {
	content: "V";
}
.icon-music-010:before {
	content: "W";
}
.icon-music-011:before {
	content: "X";
}
.icon-music-024:before {
	content: "Y";
}
.icon-music-037:before {
	content: "Z";
}
.icon-music-049:before {
	content: "0";
}
.icon-music-050:before {
	content: "1";
}
.icon-music-051:before {
	content: "2";
}
.icon-music-038:before {
	content: "3";
}
.icon-music-025:before {
	content: "4";
}
.icon-music-012:before {
	content: "5";
}
.icon-music-013:before {
	content: "6";
}
.icon-music-026:before {
	content: "7";
}
.icon-music-039:before {
	content: "8";
}
.icon-music-052:before {
	content: "9";
}
.icon-music-065:before {
	content: "!";
}
.icon-music-064:before {
	content: "\"";
}
.icon-music-063:before {
	content: "#";
}
.icon-music-078:before {
	content: "$";
}
.icon-music-091:before {
	content: "%";
}
.icon-music-090:before {
	content: "&";
}
.icon-music-077:before {
	content: "'";
}
.icon-music-076:before {
	content: "(";
}
.icon-music-089:before {
	content: ")";
}
.icon-music-088:before {
	content: "*";
}
.icon-music-075:before {
	content: "+";
}
.icon-music-074:before {
	content: ",";
}
.icon-music-087:before {
	content: "-";
}
.icon-music-086:before {
	content: ".";
}
.icon-music-073:before {
	content: "/";
}
.icon-music-072:before {
	content: ":";
}
.icon-music-085:before {
	content: ";";
}
.icon-music-084:before {
	content: "<";
}
.icon-music-071:before {
	content: "=";
}
.icon-music-070:before {
	content: ">";
}
.icon-music-083:before {
	content: "?";
}
.icon-music-082:before {
	content: "@";
}
.icon-music-069:before {
	content: "[";
}
.icon-music-068:before {
	content: "]";
}
.icon-music-081:before {
	content: "^";
}
.icon-music-080:before {
	content: "_";
}
.icon-music-067:before {
	content: "`";
}
.icon-music-066:before {
	content: "{";
}
.icon-music-079:before {
	content: "|";
}
.icon-music-092:before {
	content: "}";
}
.icon-music-093:before {
	content: "~";
}
.icon-music-094:before {
	content: "\\";
}
.icon-music-095:before {
	content: "\e000";
}
.icon-music-096:before {
	content: "\e001";
}
.icon-music-097:before {
	content: "\e002";
}
.icon-music-098:before {
	content: "\e003";
}
.icon-music-099:before {
	content: "\e004";
}
.icon-music-100:before {
	content: "\e005";
}

/* Real Estate */
@font-face {
	font-family: "real-estate";
	src:url("real-estate/webfont/fonts/real-estate.eot");
	src:url("real-estate/webfont/fonts/real-estate.eot?#iefix") format("embedded-opentype"),
	url("real-estate/webfont/fonts/real-estate.woff") format("woff"),
    url("real-estate/webfont/fonts/real-estate.ttf") format("truetype"),
	url("real-estate/webfont/fonts/real-estate.svg#real-estate") format("svg");
	font-weight: normal;
	font-style: normal;
}

[data-icon-real-estate]:before {
	font-family: "real-estate" !important;
	content: attr(data-icon-real-estate);
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

[class^="icon-real-estate"]:before,
[class*=" icon-real-estate"]:before {
	font-family: "real-estate" !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-real-estate-001:before {
	content: "a";
}
.icon-real-estate-014:before {
	content: "b";
}
.icon-real-estate-027:before {
	content: "c";
}
.icon-real-estate-040:before {
	content: "d";
}
.icon-real-estate-053:before {
	content: "e";
}
.icon-real-estate-066:before {
	content: "f";
}
.icon-real-estate-079:before {
	content: "g";
}
.icon-real-estate-080:before {
	content: "h";
}
.icon-real-estate-081:before {
	content: "i";
}
.icon-real-estate-082:before {
	content: "j";
}
.icon-real-estate-083:before {
	content: "k";
}
.icon-real-estate-084:before {
	content: "l";
}
.icon-real-estate-085:before {
	content: "m";
}
.icon-real-estate-086:before {
	content: "n";
}
.icon-real-estate-087:before {
	content: "o";
}
.icon-real-estate-088:before {
	content: "p";
}
.icon-real-estate-089:before {
	content: "q";
}
.icon-real-estate-090:before {
	content: "r";
}
.icon-real-estate-077:before {
	content: "s";
}
.icon-real-estate-078:before {
	content: "t";
}
.icon-real-estate-065:before {
	content: "u";
}
.icon-real-estate-064:before {
	content: "v";
}
.icon-real-estate-063:before {
	content: "w";
}
.icon-real-estate-076:before {
	content: "x";
}
.icon-real-estate-075:before {
	content: "y";
}
.icon-real-estate-062:before {
	content: "z";
}
.icon-real-estate-061:before {
	content: "A";
}
.icon-real-estate-074:before {
	content: "B";
}
.icon-real-estate-073:before {
	content: "C";
}
.icon-real-estate-059:before {
	content: "D";
}
.icon-real-estate-072:before {
	content: "E";
}
.icon-real-estate-060:before {
	content: "F";
}
.icon-real-estate-058:before {
	content: "G";
}
.icon-real-estate-071:before {
	content: "H";
}
.icon-real-estate-070:before {
	content: "I";
}
.icon-real-estate-057:before {
	content: "J";
}
.icon-real-estate-056:before {
	content: "K";
}
.icon-real-estate-069:before {
	content: "L";
}
.icon-real-estate-068:before {
	content: "M";
}
.icon-real-estate-055:before {
	content: "N";
}
.icon-real-estate-054:before {
	content: "O";
}
.icon-real-estate-067:before {
	content: "P";
}
.icon-real-estate-041:before {
	content: "Q";
}
.icon-real-estate-028:before {
	content: "R";
}
.icon-real-estate-015:before {
	content: "S";
}
.icon-real-estate-002:before {
	content: "T";
}
.icon-real-estate-003:before {
	content: "U";
}
.icon-real-estate-016:before {
	content: "V";
}
.icon-real-estate-029:before {
	content: "W";
}
.icon-real-estate-042:before {
	content: "X";
}
.icon-real-estate-043:before {
	content: "Y";
}
.icon-real-estate-030:before {
	content: "Z";
}
.icon-real-estate-017:before {
	content: "0";
}
.icon-real-estate-004:before {
	content: "1";
}
.icon-real-estate-005:before {
	content: "2";
}
.icon-real-estate-018:before {
	content: "3";
}
.icon-real-estate-031:before {
	content: "4";
}
.icon-real-estate-044:before {
	content: "5";
}
.icon-real-estate-045:before {
	content: "6";
}
.icon-real-estate-032:before {
	content: "7";
}
.icon-real-estate-019:before {
	content: "8";
}
.icon-real-estate-006:before {
	content: "9";
}
.icon-real-estate-008:before {
	content: "!";
}
.icon-real-estate-020:before {
	content: "\"";
}
.icon-real-estate-007:before {
	content: "#";
}
.icon-real-estate-021:before {
	content: "$";
}
.icon-real-estate-033:before {
	content: "%";
}
.icon-real-estate-034:before {
	content: "&";
}
.icon-real-estate-047:before {
	content: "'";
}
.icon-real-estate-046:before {
	content: "(";
}
.icon-real-estate-048:before {
	content: ")";
}
.icon-real-estate-035:before {
	content: "*";
}
.icon-real-estate-022:before {
	content: "+";
}
.icon-real-estate-009:before {
	content: ",";
}
.icon-real-estate-011:before {
	content: "-";
}
.icon-real-estate-023:before {
	content: ".";
}
.icon-real-estate-010:before {
	content: "/";
}
.icon-real-estate-024:before {
	content: ":";
}
.icon-real-estate-037:before {
	content: ";";
}
.icon-real-estate-036:before {
	content: "<";
}
.icon-real-estate-049:before {
	content: "=";
}
.icon-real-estate-050:before {
	content: ">";
}
.icon-real-estate-051:before {
	content: "?";
}
.icon-real-estate-038:before {
	content: "@";
}
.icon-real-estate-039:before {
	content: "[";
}
.icon-real-estate-052:before {
	content: "]";
}
.icon-real-estate-026:before {
	content: "^";
}
.icon-real-estate-025:before {
	content: "_";
}
.icon-real-estate-012:before {
	content: "`";
}
.icon-real-estate-013:before {
	content: "{";
}

/* Science */
@font-face {
	font-family: "science";
	src:url("science/webfont/fonts/science.eot");
	src:url("science/webfont/fonts/science.eot?#iefix") format("embedded-opentype"),
	url("science/webfont/fonts/science.woff") format("woff"),
    url("science/webfont/fonts/science.ttf") format("truetype"),
	url("science/webfont/fonts/science.svg#science") format("svg");
	font-weight: normal;
	font-style: normal;
}

[data-icon-science]:before {
	font-family: "science" !important;
	content: attr(data-icon-science);
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

[class^="icon-science"]:before,
[class*=" icon-science"]:before {
	font-family: "science" !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-science-001:before {
	content: "a";
}
.icon-science-014:before {
	content: "b";
}
.icon-science-002:before {
	content: "c";
}
.icon-science-015:before {
	content: "d";
}
.icon-science-016:before {
	content: "e";
}
.icon-science-003:before {
	content: "f";
}
.icon-science-004:before {
	content: "g";
}
.icon-science-017:before {
	content: "h";
}
.icon-science-019:before {
	content: "i";
}
.icon-science-005:before {
	content: "j";
}
.icon-science-018:before {
	content: "k";
}
.icon-science-006:before {
	content: "l";
}
.icon-science-007:before {
	content: "m";
}
.icon-science-020:before {
	content: "n";
}
.icon-science-021:before {
	content: "o";
}
.icon-science-008:before {
	content: "p";
}
.icon-science-009:before {
	content: "q";
}
.icon-science-022:before {
	content: "r";
}
.icon-science-023:before {
	content: "s";
}
.icon-science-010:before {
	content: "t";
}
.icon-science-011:before {
	content: "u";
}
.icon-science-024:before {
	content: "v";
}
.icon-science-025:before {
	content: "w";
}
.icon-science-012:before {
	content: "x";
}
.icon-science-013:before {
	content: "y";
}
.icon-science-026:before {
	content: "z";
}
.icon-science-039:before {
	content: "A";
}
.icon-science-052:before {
	content: "B";
}
.icon-science-065:before {
	content: "C";
}
.icon-science-078:before {
	content: "D";
}
.icon-science-077:before {
	content: "E";
}
.icon-science-064:before {
	content: "F";
}
.icon-science-051:before {
	content: "G";
}
.icon-science-038:before {
	content: "H";
}
.icon-science-037:before {
	content: "I";
}
.icon-science-050:before {
	content: "J";
}
.icon-science-063:before {
	content: "K";
}
.icon-science-076:before {
	content: "L";
}
.icon-science-075:before {
	content: "M";
}
.icon-science-062:before {
	content: "N";
}
.icon-science-049:before {
	content: "O";
}
.icon-science-036:before {
	content: "P";
}
.icon-science-035:before {
	content: "Q";
}
.icon-science-048:before {
	content: "R";
}
.icon-science-061:before {
	content: "S";
}
.icon-science-074:before {
	content: "T";
}
.icon-science-073:before {
	content: "U";
}
.icon-science-060:before {
	content: "V";
}
.icon-science-047:before {
	content: "W";
}
.icon-science-034:before {
	content: "X";
}
.icon-science-033:before {
	content: "Y";
}
.icon-science-046:before {
	content: "Z";
}
.icon-science-059:before {
	content: "0";
}
.icon-science-072:before {
	content: "1";
}
.icon-science-071:before {
	content: "2";
}
.icon-science-058:before {
	content: "3";
}
.icon-science-045:before {
	content: "4";
}
.icon-science-032:before {
	content: "5";
}
.icon-science-031:before {
	content: "6";
}
.icon-science-044:before {
	content: "7";
}
.icon-science-057:before {
	content: "8";
}
.icon-science-070:before {
	content: "9";
}
.icon-science-069:before {
	content: "!";
}
.icon-science-056:before {
	content: "\"";
}
.icon-science-043:before {
	content: "#";
}
.icon-science-030:before {
	content: "$";
}
.icon-science-029:before {
	content: "%";
}
.icon-science-042:before {
	content: "&";
}
.icon-science-055:before {
	content: "'";
}
.icon-science-068:before {
	content: "(";
}
.icon-science-067:before {
	content: ")";
}
.icon-science-054:before {
	content: "*";
}
.icon-science-041:before {
	content: "+";
}
.icon-science-028:before {
	content: ",";
}
.icon-science-027:before {
	content: "-";
}
.icon-science-040:before {
	content: ".";
}
.icon-science-053:before {
	content: "/";
}
.icon-science-066:before {
	content: ":";
}
.icon-science-079:before {
	content: ";";
}
.icon-science-092:before {
	content: "<";
}
.icon-science-105:before {
	content: "=";
}
.icon-science-118:before {
	content: ">";
}
.icon-science-131:before {
	content: "?";
}
.icon-science-144:before {
	content: "@";
}
.icon-science-145:before {
	content: "[";
}
.icon-science-132:before {
	content: "]";
}
.icon-science-119:before {
	content: "^";
}
.icon-science-106:before {
	content: "_";
}
.icon-science-093:before {
	content: "`";
}
.icon-science-080:before {
	content: "{";
}
.icon-science-081:before {
	content: "|";
}
.icon-science-094:before {
	content: "}";
}
.icon-science-107:before {
	content: "~";
}
.icon-science-120:before {
	content: "\\";
}
.icon-science-133:before {
	content: "\e000";
}
.icon-science-146:before {
	content: "\e001";
}
.icon-science-147:before {
	content: "\e002";
}
.icon-science-134:before {
	content: "\e003";
}
.icon-science-121:before {
	content: "\e004";
}
.icon-science-108:before {
	content: "\e005";
}
.icon-science-095:before {
	content: "\e006";
}
.icon-science-082:before {
	content: "\e007";
}
.icon-science-083:before {
	content: "\e008";
}
.icon-science-096:before {
	content: "\e009";
}
.icon-science-109:before {
	content: "\e00a";
}
.icon-science-122:before {
	content: "\e00b";
}
.icon-science-135:before {
	content: "\e00c";
}
.icon-science-148:before {
	content: "\e00d";
}
.icon-science-149:before {
	content: "\e00e";
}
.icon-science-136:before {
	content: "\e00f";
}
.icon-science-123:before {
	content: "\e010";
}
.icon-science-110:before {
	content: "\e011";
}
.icon-science-097:before {
	content: "\e012";
}
.icon-science-084:before {
	content: "\e013";
}
.icon-science-085:before {
	content: "\e014";
}
.icon-science-098:before {
	content: "\e015";
}
.icon-science-111:before {
	content: "\e016";
}
.icon-science-124:before {
	content: "\e017";
}
.icon-science-137:before {
	content: "\e018";
}
.icon-science-150:before {
	content: "\e019";
}
.icon-science-151:before {
	content: "\e01a";
}
.icon-science-138:before {
	content: "\e01b";
}
.icon-science-125:before {
	content: "\e01c";
}
.icon-science-112:before {
	content: "\e01d";
}
.icon-science-099:before {
	content: "\e01e";
}
.icon-science-086:before {
	content: "\e01f";
}
.icon-science-087:before {
	content: "\e020";
}
.icon-science-100:before {
	content: "\e021";
}
.icon-science-113:before {
	content: "\e022";
}
.icon-science-126:before {
	content: "\e023";
}
.icon-science-139:before {
	content: "\e024";
}
.icon-science-152:before {
	content: "\e025";
}
.icon-science-153:before {
	content: "\e026";
}
.icon-science-140:before {
	content: "\e027";
}
.icon-science-127:before {
	content: "\e028";
}
.icon-science-114:before {
	content: "\e029";
}
.icon-science-101:before {
	content: "\e02a";
}
.icon-science-088:before {
	content: "\e02b";
}
.icon-science-089:before {
	content: "\e02c";
}
.icon-science-102:before {
	content: "\e02d";
}
.icon-science-115:before {
	content: "\e02e";
}
.icon-science-128:before {
	content: "\e02f";
}
.icon-science-141:before {
	content: "\e030";
}
.icon-science-154:before {
	content: "\e031";
}
.icon-science-155:before {
	content: "\e032";
}
.icon-science-142:before {
	content: "\e033";
}
.icon-science-129:before {
	content: "\e034";
}
.icon-science-116:before {
	content: "\e035";
}
.icon-science-103:before {
	content: "\e036";
}
.icon-science-090:before {
	content: "\e037";
}
.icon-science-091:before {
	content: "\e038";
}
.icon-science-104:before {
	content: "\e039";
}
.icon-science-117:before {
	content: "\e03a";
}
.icon-science-130:before {
	content: "\e03b";
}
.icon-science-143:before {
	content: "\e03c";
}
.icon-science-156:before {
	content: "\e03d";
}
.icon-science-157:before {
	content: "\e03e";
}
.icon-science-158:before {
	content: "\e03f";
}
.icon-science-159:before {
	content: "\e040";
}
.icon-science-160:before {
	content: "\e041";
}

/* Sport */
@font-face {
	font-family: "sports-48-x-48";
	src:url("sports/webfont/fonts/sports-48-x-48.eot");
	src:url("sports/webfont/fonts/sports-48-x-48.eot?#iefix") format("embedded-opentype"),
	url("sports/webfont/fonts/sports-48-x-48.woff") format("woff"),
    url("sports/webfont/fonts/sports-48-x-48.ttf") format("truetype"),
	url("sports/webfont/fonts/sports-48-x-48.svg#sports-48-x-48") format("svg");
	font-weight: normal;
	font-style: normal;
}

[data-icon-sport]:before {
	font-family: "sports-48-x-48" !important;
	content: attr(data-icon-sport);
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

[class^="icon-sport"]:before,
[class*="icon-sport"]:before {
	font-family: "sports-48-x-48" !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

[class^="icon-christmas"]:before,
[class*=" icon-christmas"]:before {
	font-family: "cristmas" !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-sport-001:before {
	content: "a";
}
.icon-sport-014:before {
	content: "b";
}
.icon-sport-027:before {
	content: "c";
}
.icon-sport-028:before {
	content: "d";
}
.icon-sport-015:before {
	content: "e";
}
.icon-sport-002:before {
	content: "f";
}
.icon-sport-003:before {
	content: "g";
}
.icon-sport-016:before {
	content: "h";
}
.icon-sport-029:before {
	content: "i";
}
.icon-sport-030:before {
	content: "j";
}
.icon-sport-017:before {
	content: "k";
}
.icon-sport-004:before {
	content: "l";
}
.icon-sport-005:before {
	content: "m";
}
.icon-sport-018:before {
	content: "n";
}
.icon-sport-031:before {
	content: "o";
}
.icon-sport-032:before {
	content: "p";
}
.icon-sport-019:before {
	content: "q";
}
.icon-sport-006:before {
	content: "r";
}
.icon-sport-007:before {
	content: "s";
}
.icon-sport-020:before {
	content: "t";
}
.icon-sport-033:before {
	content: "u";
}
.icon-sport-034:before {
	content: "v";
}
.icon-sport-021:before {
	content: "w";
}
.icon-sport-008:before {
	content: "x";
}
.icon-sport-009:before {
	content: "y";
}
.icon-sport-022:before {
	content: "z";
}
.icon-sport-035:before {
	content: "A";
}
.icon-sport-036:before {
	content: "B";
}
.icon-sport-023:before {
	content: "C";
}
.icon-sport-010:before {
	content: "D";
}
.icon-sport-011:before {
	content: "E";
}
.icon-sport-024:before {
	content: "F";
}
.icon-sport-037:before {
	content: "G";
}
.icon-sport-038:before {
	content: "H";
}
.icon-sport-025:before {
	content: "I";
}
.icon-sport-012:before {
	content: "J";
}
.icon-sport-013:before {
	content: "K";
}
.icon-sport-026:before {
	content: "L";
}
.icon-sport-039:before {
	content: "M";
}
.icon-sport-040:before {
	content: "N";
}
.icon-sport-053:before {
	content: "O";
}
.icon-sport-066:before {
	content: "P";
}
.icon-sport-079:before {
	content: "Q";
}
.icon-sport-080:before {
	content: "R";
}
.icon-sport-067:before {
	content: "S";
}
.icon-sport-054:before {
	content: "T";
}
.icon-sport-041:before {
	content: "U";
}
.icon-sport-042:before {
	content: "V";
}
.icon-sport-055:before {
	content: "W";
}
.icon-sport-068:before {
	content: "X";
}
.icon-sport-081:before {
	content: "Y";
}
.icon-sport-082:before {
	content: "Z";
}
.icon-sport-069:before {
	content: "0";
}
.icon-sport-056:before {
	content: "1";
}
.icon-sport-043:before {
	content: "2";
}
.icon-sport-044:before {
	content: "3";
}
.icon-sport-057:before {
	content: "4";
}
.icon-sport-070:before {
	content: "5";
}
.icon-sport-083:before {
	content: "6";
}
.icon-sport-084:before {
	content: "7";
}
.icon-sport-071:before {
	content: "8";
}
.icon-sport-058:before {
	content: "9";
}
.icon-sport-045:before {
	content: "!";
}
.icon-sport-046:before {
	content: "\"";
}
.icon-sport-059:before {
	content: "#";
}
.icon-sport-072:before {
	content: "$";
}
.icon-sport-085:before {
	content: "%";
}
.icon-sport-086:before {
	content: "&";
}
.icon-sport-073:before {
	content: "'";
}
.icon-sport-060:before {
	content: "(";
}
.icon-sport-047:before {
	content: ")";
}
.icon-sport-048:before {
	content: "*";
}
.icon-sport-061:before {
	content: "+";
}
.icon-sport-074:before {
	content: ",";
}
.icon-sport-087:before {
	content: "-";
}
.icon-sport-075:before {
	content: ".";
}
.icon-sport-062:before {
	content: "/";
}
.icon-sport-049:before {
	content: ":";
}
.icon-sport-050:before {
	content: ";";
}
.icon-sport-063:before {
	content: "<";
}
.icon-sport-064:before {
	content: "=";
}
.icon-sport-051:before {
	content: ">";
}
.icon-sport-052:before {
	content: "?";
}
.icon-sport-065:before {
	content: "@";
}
.icon-sport-078:before {
	content: "[";
}
.icon-sport-091:before {
	content: "]";
}
.icon-sport-090:before {
	content: "^";
}
.icon-sport-077:before {
	content: "_";
}
.icon-sport-076:before {
	content: "`";
}
.icon-sport-089:before {
	content: "{";
}
.icon-sport-088:before {
	content: "|";
}
.icon-sport-092:before {
	content: "}";
}
.icon-sport-105:before {
	content: "~";
}
.icon-sport-118:before {
	content: "\\";
}
.icon-sport-131:before {
	content: "\e000";
}
.icon-sport-144:before {
	content: "\e001";
}
.icon-sport-145:before {
	content: "\e002";
}
.icon-sport-132:before {
	content: "\e003";
}
.icon-sport-119:before {
	content: "\e004";
}
.icon-sport-106:before {
	content: "\e005";
}
.icon-sport-093:before {
	content: "\e006";
}
.icon-sport-094:before {
	content: "\e007";
}
.icon-sport-107:before {
	content: "\e008";
}
.icon-sport-120:before {
	content: "\e009";
}
.icon-sport-133:before {
	content: "\e00a";
}
.icon-sport-146:before {
	content: "\e00b";
}
.icon-sport-147:before {
	content: "\e00c";
}
.icon-sport-134:before {
	content: "\e00d";
}
.icon-sport-121:before {
	content: "\e00e";
}
.icon-sport-108:before {
	content: "\e00f";
}
.icon-sport-095:before {
	content: "\e010";
}
.icon-sport-096:before {
	content: "\e011";
}
.icon-sport-109:before {
	content: "\e012";
}
.icon-sport-122:before {
	content: "\e013";
}
.icon-sport-135:before {
	content: "\e014";
}
.icon-sport-148:before {
	content: "\e015";
}
.icon-sport-149:before {
	content: "\e016";
}
.icon-sport-136:before {
	content: "\e017";
}
.icon-sport-123:before {
	content: "\e018";
}
.icon-sport-110:before {
	content: "\e019";
}
.icon-sport-097:before {
	content: "\e01a";
}
.icon-sport-098:before {
	content: "\e01b";
}
.icon-sport-111:before {
	content: "\e01c";
}
.icon-sport-124:before {
	content: "\e01d";
}
.icon-sport-137:before {
	content: "\e01e";
}
.icon-sport-150:before {
	content: "\e01f";
}
.icon-sport-151:before {
	content: "\e020";
}
.icon-sport-138:before {
	content: "\e021";
}
.icon-sport-125:before {
	content: "\e022";
}
.icon-sport-112:before {
	content: "\e023";
}
.icon-sport-099:before {
	content: "\e024";
}
.icon-sport-100:before {
	content: "\e025";
}
.icon-sport-113:before {
	content: "\e026";
}
.icon-sport-126:before {
	content: "\e027";
}
.icon-sport-139:before {
	content: "\e028";
}
.icon-sport-152:before {
	content: "\e029";
}
.icon-sport-153:before {
	content: "\e02a";
}
.icon-sport-140:before {
	content: "\e02b";
}
.icon-sport-127:before {
	content: "\e02c";
}
.icon-sport-114:before {
	content: "\e02d";
}
.icon-sport-101:before {
	content: "\e02e";
}
.icon-sport-102:before {
	content: "\e02f";
}
.icon-sport-115:before {
	content: "\e030";
}
.icon-sport-128:before {
	content: "\e031";
}
.icon-sport-141:before {
	content: "\e032";
}
.icon-sport-154:before {
	content: "\e033";
}
.icon-sport-155:before {
	content: "\e034";
}
.icon-sport-142:before {
	content: "\e035";
}
.icon-sport-129:before {
	content: "\e036";
}
.icon-sport-116:before {
	content: "\e037";
}
.icon-sport-103:before {
	content: "\e038";
}
.icon-sport-104:before {
	content: "\e039";
}
.icon-sport-117:before {
	content: "\e03a";
}
.icon-sport-130:before {
	content: "\e03b";
}
.icon-sport-143:before {
	content: "\e03c";
}
.icon-sport-156:before {
	content: "\e03d";
}
.icon-sport-157:before {
	content: "\e03e";
}
.icon-sport-170:before {
	content: "\e03f";
}
.icon-sport-183:before {
	content: "\e040";
}
.icon-sport-196:before {
	content: "\e041";
}
.icon-sport-197:before {
	content: "\e042";
}
.icon-sport-184:before {
	content: "\e043";
}
.icon-sport-171:before {
	content: "\e044";
}
.icon-sport-158:before {
	content: "\e045";
}
.icon-sport-159:before {
	content: "\e046";
}
.icon-sport-172:before {
	content: "\e047";
}
.icon-sport-185:before {
	content: "\e048";
}
.icon-sport-198:before {
	content: "\e049";
}
.icon-sport-199:before {
	content: "\e04a";
}
.icon-sport-186:before {
	content: "\e04b";
}
.icon-sport-173:before {
	content: "\e04c";
}
.icon-sport-160:before {
	content: "\e04d";
}
.icon-sport-174:before {
	content: "\e04e";
}
.icon-sport-187:before {
	content: "\e04f";
}
.icon-sport-200:before {
	content: "\e050";
}
.icon-sport-188:before {
	content: "\e051";
}
.icon-sport-175:before {
	content: "\e052";
}
.icon-sport-162:before {
	content: "\e053";
}
.icon-sport-163:before {
	content: "\e054";
}
.icon-sport-176:before {
	content: "\e055";
}
.icon-sport-189:before {
	content: "\e056";
}
.icon-sport-190:before {
	content: "\e057";
}
.icon-sport-177:before {
	content: "\e058";
}
.icon-sport-164:before {
	content: "\e059";
}
.icon-sport-165:before {
	content: "\e05a";
}
.icon-sport-178:before {
	content: "\e05b";
}
.icon-sport-191:before {
	content: "\e05c";
}
.icon-sport-192:before {
	content: "\e05d";
}
.icon-sport-179:before {
	content: "\e05e";
}
.icon-sport-166:before {
	content: "\e05f";
}
.icon-sport-167:before {
	content: "\e060";
}
.icon-sport-180:before {
	content: "\e061";
}
.icon-sport-193:before {
	content: "\e062";
}
.icon-sport-194:before {
	content: "\e063";
}
.icon-sport-181:before {
	content: "\e064";
}
.icon-sport-168:before {
	content: "\e065";
}
.icon-sport-169:before {
	content: "\e066";
}
.icon-sport-182:before {
	content: "\e067";
}
.icon-sport-195:before {
	content: "\e068";
}
.icon-sport-161:before {
	content: "\e069";
}

/* Travel */
@font-face {
	font-family: "travel";
	src:url("travel/webfont/fonts/travel.eot");
	src:url("travel/webfont/fonts/travel.eot?#iefix") format("embedded-opentype"),
	url("travel/webfont/fonts/travel.woff") format("woff"),
    url("travel/webfont/fonts/travel.ttf") format("truetype"),
	url("travel/webfont/fonts/travel.svg#travel") format("svg");
	font-weight: normal;
	font-style: normal;
}

[data-icon-travel]:before {
	font-family: "travel" !important;
	content: attr(data-icon-travel);
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

[class^="icon-travel"]:before,
[class*=" icon-travel"]:before {
	font-family: "travel" !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-travel-001:before {
	content: "a";
}
.icon-travel-014:before {
	content: "b";
}
.icon-travel-015:before {
	content: "c";
}
.icon-travel-002:before {
	content: "d";
}
.icon-travel-003:before {
	content: "e";
}
.icon-travel-016:before {
	content: "f";
}
.icon-travel-017:before {
	content: "g";
}
.icon-travel-004:before {
	content: "h";
}
.icon-travel-005:before {
	content: "i";
}
.icon-travel-018:before {
	content: "j";
}
.icon-travel-019:before {
	content: "k";
}
.icon-travel-006:before {
	content: "l";
}
.icon-travel-007:before {
	content: "m";
}
.icon-travel-020:before {
	content: "n";
}
.icon-travel-021:before {
	content: "o";
}
.icon-travel-008:before {
	content: "p";
}
.icon-travel-009:before {
	content: "q";
}
.icon-travel-022:before {
	content: "r";
}
.icon-travel-023:before {
	content: "s";
}
.icon-travel-010:before {
	content: "t";
}
.icon-travel-011:before {
	content: "u";
}
.icon-travel-024:before {
	content: "v";
}
.icon-travel-025:before {
	content: "w";
}
.icon-travel-012:before {
	content: "x";
}
.icon-travel-013:before {
	content: "y";
}
.icon-travel-026:before {
	content: "z";
}
.icon-travel-039:before {
	content: "A";
}
.icon-travel-052:before {
	content: "B";
}
.icon-travel-065:before {
	content: "C";
}
.icon-travel-064:before {
	content: "D";
}
.icon-travel-051:before {
	content: "E";
}
.icon-travel-038:before {
	content: "F";
}
.icon-travel-037:before {
	content: "G";
}
.icon-travel-050:before {
	content: "H";
}
.icon-travel-063:before {
	content: "I";
}
.icon-travel-062:before {
	content: "J";
}
.icon-travel-049:before {
	content: "K";
}
.icon-travel-036:before {
	content: "L";
}
.icon-travel-035:before {
	content: "M";
}
.icon-travel-048:before {
	content: "N";
}
.icon-travel-061:before {
	content: "O";
}
.icon-travel-060:before {
	content: "P";
}
.icon-travel-047:before {
	content: "Q";
}
.icon-travel-034:before {
	content: "R";
}
.icon-travel-033:before {
	content: "S";
}
.icon-travel-046:before {
	content: "T";
}
.icon-travel-059:before {
	content: "U";
}
.icon-travel-058:before {
	content: "V";
}
.icon-travel-045:before {
	content: "W";
}
.icon-travel-032:before {
	content: "X";
}
.icon-travel-031:before {
	content: "Y";
}
.icon-travel-044:before {
	content: "Z";
}
.icon-travel-057:before {
	content: "0";
}
.icon-travel-056:before {
	content: "1";
}
.icon-travel-043:before {
	content: "2";
}
.icon-travel-030:before {
	content: "3";
}
.icon-travel-029:before {
	content: "4";
}
.icon-travel-042:before {
	content: "5";
}
.icon-travel-055:before {
	content: "6";
}
.icon-travel-054:before {
	content: "7";
}
.icon-travel-041:before {
	content: "8";
}
.icon-travel-028:before {
	content: "9";
}
.icon-travel-027:before {
	content: "!";
}
.icon-travel-040:before {
	content: "\"";
}
.icon-travel-053:before {
	content: "#";
}
.icon-travel-066:before {
	content: "$";
}
.icon-travel-079:before {
	content: "%";
}
.icon-travel-092:before {
	content: "&";
}
.icon-travel-105:before {
	content: "'";
}
.icon-travel-106:before {
	content: "(";
}
.icon-travel-093:before {
	content: ")";
}
.icon-travel-080:before {
	content: "*";
}
.icon-travel-067:before {
	content: "+";
}
.icon-travel-068:before {
	content: ",";
}
.icon-travel-081:before {
	content: "-";
}
.icon-travel-094:before {
	content: ".";
}
.icon-travel-107:before {
	content: "/";
}
.icon-travel-108:before {
	content: ":";
}
.icon-travel-095:before {
	content: ";";
}
.icon-travel-082:before {
	content: "<";
}
.icon-travel-069:before {
	content: "=";
}
.icon-travel-070:before {
	content: ">";
}
.icon-travel-083:before {
	content: "?";
}
.icon-travel-096:before {
	content: "@";
}
.icon-travel-109:before {
	content: "[";
}
.icon-travel-110:before {
	content: "]";
}
.icon-travel-097:before {
	content: "^";
}
.icon-travel-084:before {
	content: "_";
}
.icon-travel-071:before {
	content: "`";
}
.icon-travel-072:before {
	content: "{";
}
.icon-travel-085:before {
	content: "|";
}
.icon-travel-098:before {
	content: "}";
}
.icon-travel-111:before {
	content: "~";
}
.icon-travel-112:before {
	content: "\\";
}
.icon-travel-099:before {
	content: "\e000";
}
.icon-travel-086:before {
	content: "\e001";
}
.icon-travel-073:before {
	content: "\e002";
}
.icon-travel-074:before {
	content: "\e003";
}
.icon-travel-087:before {
	content: "\e004";
}
.icon-travel-100:before {
	content: "\e005";
}
.icon-travel-113:before {
	content: "\e006";
}
.icon-travel-114:before {
	content: "\e007";
}
.icon-travel-101:before {
	content: "\e008";
}
.icon-travel-088:before {
	content: "\e009";
}
.icon-travel-075:before {
	content: "\e00a";
}
.icon-travel-076:before {
	content: "\e00b";
}
.icon-travel-089:before {
	content: "\e00c";
}
.icon-travel-102:before {
	content: "\e00d";
}
.icon-travel-115:before {
	content: "\e00e";
}
.icon-travel-116:before {
	content: "\e00f";
}
.icon-travel-103:before {
	content: "\e010";
}
.icon-travel-090:before {
	content: "\e011";
}
.icon-travel-077:before {
	content: "\e012";
}
.icon-travel-091:before {
	content: "\e013";
}
.icon-travel-104:before {
	content: "\e014";
}
.icon-travel-117:before {
	content: "\e015";
}
.icon-travel-078:before {
	content: "\e016";
}
.icon-travel-130:before {
	content: "\e017";
}
.icon-travel-143:before {
	content: "\e018";
}
.icon-travel-142:before {
	content: "\e019";
}
.icon-travel-129:before {
	content: "\e01a";
}
.icon-travel-128:before {
	content: "\e01b";
}
.icon-travel-141:before {
	content: "\e01c";
}
.icon-travel-140:before {
	content: "\e01d";
}
.icon-travel-127:before {
	content: "\e01e";
}
.icon-travel-126:before {
	content: "\e01f";
}
.icon-travel-139:before {
	content: "\e020";
}
.icon-travel-138:before {
	content: "\e021";
}
.icon-travel-125:before {
	content: "\e022";
}
.icon-travel-124:before {
	content: "\e023";
}
.icon-travel-137:before {
	content: "\e024";
}
.icon-travel-150:before {
	content: "\e025";
}
.icon-travel-149:before {
	content: "\e026";
}
.icon-travel-136:before {
	content: "\e027";
}
.icon-travel-123:before {
	content: "\e028";
}
.icon-travel-122:before {
	content: "\e029";
}
.icon-travel-135:before {
	content: "\e02a";
}
.icon-travel-148:before {
	content: "\e02b";
}
.icon-travel-147:before {
	content: "\e02c";
}
.icon-travel-134:before {
	content: "\e02d";
}
.icon-travel-121:before {
	content: "\e02e";
}
.icon-travel-120:before {
	content: "\e02f";
}
.icon-travel-133:before {
	content: "\e030";
}
.icon-travel-146:before {
	content: "\e031";
}
.icon-travel-145:before {
	content: "\e032";
}
.icon-travel-132:before {
	content: "\e033";
}
.icon-travel-119:before {
	content: "\e034";
}
.icon-travel-118:before {
	content: "\e035";
}
.icon-travel-131:before {
	content: "\e036";
}
.icon-travel-144:before {
	content: "\e037";
}

/* Weather */
@font-face {
	font-family: "weather";
	src:url("weather/webfont/fonts/weather.eot");
	src:url("weather/webfont/fonts/weather.eot?#iefix") format("embedded-opentype"),
	url("weather/webfont/fonts/weather.woff") format("woff"),
    url("weather/webfont/fonts/weather.ttf") format("truetype"),
	url("weather/webfont/fonts/weather.svg#weather") format("svg");
	font-weight: normal;
	font-style: normal;
}

[data-icon-weather]:before {
	font-family: "weather" !important;
	content: attr(data-icon-weather);
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

[class^="icon-weather"]:before,
[class*=" icon-weather"]:before {
	font-family: "weather" !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-weather-001:before {
	content: "a";
}
.icon-weather-014:before {
	content: "b";
}
.icon-weather-027:before {
	content: "c";
}
.icon-weather-040:before {
	content: "d";
}
.icon-weather-053:before {
	content: "e";
}
.icon-weather-054:before {
	content: "f";
}
.icon-weather-041:before {
	content: "g";
}
.icon-weather-028:before {
	content: "h";
}
.icon-weather-015:before {
	content: "i";
}
.icon-weather-002:before {
	content: "j";
}
.icon-weather-003:before {
	content: "k";
}
.icon-weather-016:before {
	content: "l";
}
.icon-weather-029:before {
	content: "m";
}
.icon-weather-042:before {
	content: "n";
}
.icon-weather-055:before {
	content: "o";
}
.icon-weather-056:before {
	content: "p";
}
.icon-weather-043:before {
	content: "q";
}
.icon-weather-030:before {
	content: "r";
}
.icon-weather-017:before {
	content: "s";
}
.icon-weather-004:before {
	content: "t";
}
.icon-weather-005:before {
	content: "u";
}
.icon-weather-018:before {
	content: "v";
}
.icon-weather-031:before {
	content: "w";
}
.icon-weather-044:before {
	content: "x";
}
.icon-weather-057:before {
	content: "y";
}
.icon-weather-058:before {
	content: "z";
}
.icon-weather-045:before {
	content: "A";
}
.icon-weather-032:before {
	content: "B";
}
.icon-weather-019:before {
	content: "C";
}
.icon-weather-006:before {
	content: "D";
}
.icon-weather-007:before {
	content: "E";
}
.icon-weather-020:before {
	content: "F";
}
.icon-weather-033:before {
	content: "G";
}
.icon-weather-046:before {
	content: "H";
}
.icon-weather-059:before {
	content: "I";
}
.icon-weather-060:before {
	content: "J";
}
.icon-weather-047:before {
	content: "K";
}
.icon-weather-034:before {
	content: "L";
}
.icon-weather-021:before {
	content: "M";
}
.icon-weather-008:before {
	content: "N";
}
.icon-weather-009:before {
	content: "O";
}
.icon-weather-022:before {
	content: "P";
}
.icon-weather-035:before {
	content: "Q";
}
.icon-weather-048:before {
	content: "R";
}
.icon-weather-049:before {
	content: "S";
}
.icon-weather-036:before {
	content: "T";
}
.icon-weather-023:before {
	content: "U";
}
.icon-weather-010:before {
	content: "V";
}
.icon-weather-011:before {
	content: "W";
}
.icon-weather-024:before {
	content: "X";
}
.icon-weather-037:before {
	content: "Y";
}
.icon-weather-050:before {
	content: "Z";
}
.icon-weather-051:before {
	content: "0";
}
.icon-weather-038:before {
	content: "1";
}
.icon-weather-025:before {
	content: "2";
}
.icon-weather-012:before {
	content: "3";
}
.icon-weather-013:before {
	content: "4";
}
.icon-weather-026:before {
	content: "5";
}
.icon-weather-039:before {
	content: "6";
}
.icon-weather-052:before {
	content: "7";
}

/* Transport */
@font-face {
	font-family: "transport";
	src:url("transport/webfont/fonts/transport.eot");
	src:url("transport/webfont/fonts/transport.eot?#iefix") format("embedded-opentype"),
	url("transport/webfont/fonts/transport.woff") format("woff"),
    url("transport/webfont/fonts/transport.ttf") format("truetype"),
	url("transport/webfont/fonts/transport.svg#transport") format("svg");
	font-weight: normal;
	font-style: normal;
}

[data-icon-transport]:before {
	font-family: "transport" !important;
	content: attr(data-icon-transport);
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

[class^="icon-transport"]:before,
[class*=" icon-transport"]:before {
	font-family: "transport" !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-transport-001:before {
  content: "a";
}
.icon-transport-014:before {
  content: "b";
}
.icon-transport-015:before {
  content: "c";
}
.icon-transport-002:before {
  content: "d";
}
.icon-transport-003:before {
  content: "e";
}
.icon-transport-016:before {
  content: "f";
}
.icon-transport-017:before {
  content: "g";
}
.icon-transport-004:before {
  content: "h";
}
.icon-transport-005:before {
  content: "i";
}
.icon-transport-018:before {
  content: "j";
}
.icon-transport-019:before {
  content: "k";
}
.icon-transport-006:before {
  content: "l";
}
.icon-transport-007:before {
  content: "m";
}
.icon-transport-020:before {
  content: "n";
}
.icon-transport-021:before {
  content: "o";
}
.icon-transport-008:before {
  content: "p";
}
.icon-transport-009:before {
  content: "q";
}
.icon-transport-022:before {
  content: "r";
}
.icon-transport-023:before {
  content: "s";
}
.icon-transport-010:before {
  content: "t";
}
.icon-transport-024:before {
  content: "u";
}
.icon-transport-025:before {
  content: "v";
}
.icon-transport-012:before {
  content: "w";
}
.icon-transport-011:before {
  content: "x";
}
.icon-transport-013:before {
  content: "y";
}
.icon-transport-026:before {
  content: "z";
}
.icon-transport-039:before {
  content: "A";
}
.icon-transport-052:before {
  content: "B";
}
.icon-transport-051:before {
  content: "C";
}
.icon-transport-038:before {
  content: "D";
}
.icon-transport-037:before {
  content: "E";
}
.icon-transport-050:before {
  content: "F";
}
.icon-transport-049:before {
  content: "G";
}
.icon-transport-036:before {
  content: "H";
}
.icon-transport-035:before {
  content: "I";
}
.icon-transport-048:before {
  content: "J";
}
.icon-transport-047:before {
  content: "K";
}
.icon-transport-034:before {
  content: "L";
}
.icon-transport-033:before {
  content: "M";
}
.icon-transport-046:before {
  content: "N";
}
.icon-transport-045:before {
  content: "O";
}
.icon-transport-032:before {
  content: "P";
}
.icon-transport-031:before {
  content: "Q";
}
.icon-transport-044:before {
  content: "R";
}
.icon-transport-043:before {
  content: "S";
}
.icon-transport-030:before {
  content: "T";
}
.icon-transport-029:before {
  content: "U";
}
.icon-transport-042:before {
  content: "V";
}
.icon-transport-041:before {
  content: "W";
}
.icon-transport-028:before {
  content: "X";
}
.icon-transport-027:before {
  content: "Y";
}
.icon-transport-040:before {
  content: "Z";
}
.icon-transport-053:before {
  content: "0";
}
.icon-transport-066:before {
  content: "1";
}
.icon-transport-079:before {
  content: "2";
}
.icon-transport-092:before {
  content: "3";
}
.icon-transport-093:before {
  content: "4";
}
.icon-transport-080:before {
  content: "5";
}
.icon-transport-067:before {
  content: "6";
}
.icon-transport-054:before {
  content: "7";
}
.icon-transport-055:before {
  content: "8";
}
.icon-transport-068:before {
  content: "9";
}
.icon-transport-081:before {
  content: "!";
}
.icon-transport-094:before {
  content: "\"";
}
.icon-transport-095:before {
  content: "#";
}
.icon-transport-082:before {
  content: "$";
}
.icon-transport-069:before {
  content: "%";
}
.icon-transport-056:before {
  content: "&";
}
.icon-transport-057:before {
  content: "'";
}
.icon-transport-070:before {
  content: "(";
}
.icon-transport-083:before {
  content: ")";
}
.icon-transport-096:before {
  content: "*";
}
.icon-transport-097:before {
  content: "+";
}
.icon-transport-084:before {
  content: ",";
}
.icon-transport-071:before {
  content: "-";
}
.icon-transport-058:before {
  content: ".";
}
.icon-transport-059:before {
  content: "/";
}
.icon-transport-072:before {
  content: ":";
}
.icon-transport-085:before {
  content: ";";
}
.icon-transport-098:before {
  content: "<";
}
.icon-transport-099:before {
  content: "=";
}
.icon-transport-086:before {
  content: ">";
}
.icon-transport-073:before {
  content: "?";
}
.icon-transport-060:before {
  content: "@";
}
.icon-transport-061:before {
  content: "[";
}
.icon-transport-074:before {
  content: "]";
}
.icon-transport-087:before {
  content: "^";
}
.icon-transport-100:before {
  content: "_";
}
.icon-transport-088:before {
  content: "`";
}
.icon-transport-075:before {
  content: "{";
}
.icon-transport-076:before {
  content: "|";
}
.icon-transport-089:before {
  content: "}";
}
.icon-transport-090:before {
  content: "~";
}
.icon-transport-077:before {
  content: "\\";
}
.icon-transport-078:before {
  content: "\e000";
}
.icon-transport-091:before {
  content: "\e001";
}
.icon-transport-065:before {
  content: "\e002";
}
.icon-transport-064:before {
  content: "\e003";
}
.icon-transport-063:before {
  content: "\e004";
}
.icon-transport-062:before {
  content: "\e005";
}
