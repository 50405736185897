.ui-select-search {
  width: 100%!important;
}

.ui-select-refreshing.fa.fa-spin.fa-spin {
  z-index: 10;
  padding: 0;
  right: 13px;
  top: 13px;
  font-family: 'FontAwesome';

}

.further-left.ui-select-refreshing.fa.fa-spin.fa-spin {
  right: 47px;
}

/* ----- UI Select ----- */

.ui-select-multiple.ui-select-bootstrap4 {
  .ui-select-match-item {
    margin-bottom: 3px;
  }

  .ui-select-search {
    border: none;
    outline: none;
  }

  .ui-select-choices-group {
    padding: 7px;
    cursor: pointer;
  }

  .ui-select-match {
    .close {
      line-height: .95;
    }

    .btn-primary {
      background-color: #868e96;
      border-color: #868e96;
      color: #fff;
    }
  }
}

.ui-select-choices-row > span {
  white-space: initial !important;
}

.ui-select-choices-group {
  div.active {
    .text-muted {
      color: #fff !important;
    }
  }
}

.ui-select-match-item {
  &.btn-secondary {
    color: inherit;
  }
}

.ui-select-match-text span {
  display: inherit!important;
}

.ui-select-toggle {
  min-height: 33.5px !important;
  overflow:hidden;
  text-overflow: ellipsis;

  svg.fa-caret-down {
    position: absolute;
    top: 9px;
    right: 10px;
  }
}

.ui-select-choices.ui-select-choices-content {
  min-width: 260px;
  width: 100%;
  padding: 0;
}
